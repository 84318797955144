<div class="feedback-form-inner">
    <div class="row">
        <div class="col-lg-5 col-md-12">
            <div class="feedback-form-image">
                <img src="assets/img/note-pad.png" alt="image">
            </div>
        </div>
        <div class="col-lg-7 col-md-12">
            <div class="feedback-form">
                <h3 class="cs-pale-blue" style="color: var(--mainColor)!important;">Here is Feedback Form</h3>
                <form #feedbackForm="ngForm" (ngSubmit)="feedback_submit(feedbackForm.value)">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="single-testimonials-item">
                                <div class="form-group">
                                    <textarea cols="30" minlength="5" required ngModel name="message" #message="ngModel" [class.invalid_input]="message.touched && !message.valid" rows="4" class="form-control" placeholder="Type your feedback here"></textarea>
                                    <div class="err_msg" *ngIf="message.touched && !message.valid">
                                        <div *ngIf="message.errors.required">Your feedback is required.</div>
                                        <div *ngIf="message.errors.minlength">Your feedback should have at least {{ message.errors.minlength.requiredLength }} characters.</div>
                                    </div>
                                </div>
                                <div class="info">
                                    <img src="{{currentUserProfileUrl}}" class="shadow rounded-circle" alt="image">
                                    <h4>{{currentUserName}}</h4>
                                    <span>{{currentUserType}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <button type="submit" *ngIf="feedbackForm.valid && !feedbackSubmitPending" class="default-btn" style="float: right;border-radius: 20px 0px 20px 10px ;">
                                <i class='bx bx-send icon-arrow before'></i>
                                <span class="label">Submit</span>
                                <i class="bx bx-send icon-arrow after"></i>
                            </button>
                            <button type="button" *ngIf="!feedbackForm.valid && !feedbackSubmitPending" disabled style="cursor: not-allowed;float: right;border-radius: 20px 0px 20px 10px ;" class="default-btn">
                                <i class='bx bx-send icon-arrow before'></i>
                                <span class="label">Submit</span>
                                <i class="bx bx-send icon-arrow after"></i>
                            </button>
                            <button type="button" *ngIf="feedbackForm.valid && feedbackSubmitPending" style="cursor: not-allowed;float: right;border-radius: 20px 0px 20px 10px ;" class="default-btn">
                                <i class='icon-arrow before'><i class="bx bx-loader-alt fa-spin "></i></i>
                                <span class="label">Submitting</span>
                                <i class='icon-arrow after'><i class="bx bx-loader-alt fa-spin "></i></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>