<div class="courses-categories-slides">
    <owl-carousel-o [options]="categoriesSlides">
        <ng-template carouselSlide>
            <div class="single-categories-courses-item bg1 mb-30">
                <div class="icon">
                    <i class='bx bx-code-alt'></i>
                </div>
                <h3>Web Development</h3>
                <span>60 Courses</span>
                <a routerLink="/" class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a>
                <a routerLink="/" class="link-btn"></a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-item bg2 mb-30">
                <div class="icon">
                    <i class='bx bx-camera'></i>
                </div>
                <h3>Photography </h3>
                <span>21 Courses</span>
                <a routerLink="/" class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a>
                <a routerLink="/" class="link-btn"></a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-item bg3 mb-30">
                <div class="icon">
                    <i class='bx bx-layer'></i>
                </div>
                <h3>Graphics Design</h3>
                <span>58 Courses</span>
                <a routerLink="/" class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a>
                <a routerLink="/" class="link-btn"></a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-item bg4 mb-30">
                <div class="icon">
                    <i class='bx bxs-flag-checkered'></i>
                </div>
                <h3>Web Language</h3>
                <span>99 Courses</span>
                <a routerLink="/" class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a>
                <a routerLink="/" class="link-btn"></a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-item bg5 mb-30">
                <div class="icon">
                    <i class='bx bx-health'></i>
                </div>
                <h3>Health & Fitness</h3>
                <span>21 Courses</span>
                <a routerLink="/" class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a>
                <a routerLink="/" class="link-btn"></a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-item bg6 mb-30">
                <div class="icon">
                    <i class='bx bx-line-chart'></i>
                </div>
                <h3>Business Studies</h3>
                <span>49 Courses</span>
                <a routerLink="/" class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a>
                <a routerLink="/" class="link-btn"></a>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>