<app-header></app-header>
<br><br><br><br>

<div class="courses-details-area pt-100 pb-70">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <div class="courses-title">
                        <div class="row">
                            <div class="col-10">
                                <h2 routerLink="/learn-film-marking-with-dbima" class="cursor_p">Film Marking With DBIMA
                                </h2>
                                <h4>Exam 02</h4>
                            </div>
                            <div class="col-2" style="text-align: right;">
                                <a href="learn-film-marking-with-dbima/dashboard"><i class='bx bx-grid-alt cs-xlarge cursor_p cs-text-gray cs_nav_icon' style="margin-top: 20px;"></i></a>
                            </div>
                        </div>

                        <br>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">

            <div class="col-lg-4">
                <div class="courses-sidebar-information">
                    <h4>Module Overview</h4>
                    <ul>
                        <li>
                            <div onclick="toggleView('lessons')" class="cursor_p">
                                <span><i class='bx bx-book'></i> Exams:</span> 1 /2
                            </div>

                            <br><br>

                            <div class="cs-animate-zoom" id="lessons">

                                <div>
                                    <a class="cs-button cs-block cs-padding-16 cs-round row" href="learn-film-marking-with-dbima/exam-mcq" style="margin-left: 5px;">
                                        <div class="col-10 cs-left-align">
                                            <label>Exam 01</label>
                                        </div>
                                        <div class="col-2">
                                            <span><i class="bx bx-check-double cs-xlarge"></i></span>
                                        </div>
                                    </a>

                                    <button class="cs-button cs-block cs-padding-16 cs-round row cs-light-gray" style="margin-left: 5px;cursor: default;">
                                        <div class="col-10 cs-left-align">
                                            <label>Exam 02</label>
                                        </div>
                                        <div class="col-2">
                                            <span><i class="bx cs-xlarge" [class.bx-check-double]="exam02Completed" [class.bx-loader-alt]="!exam02Completed"></i></span>
                                        </div>
                                    </button>

                                </div>
                            </div>

                        </li>

                        <li>
                            <span><i class='bx bx-atom'></i> Quizzes:</span> <i class="bx bx-check-double cs-xlarge cs-text-green"></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-1"></div>

            <div class="col-lg-6">
                <div class="courses-details-image cs-card">
                    <div style="padding: 30px;">
                        <div class="courses-details-header">
                            <div class="courses-title">
                                <h2>FINAL PROJECT</h2>
                                <h4>(Instructions & Guidelines)</h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.</p>
                            </div>
                        </div>

                        <div class="courses-details-desc">

                            <button id="project_link_submission_btn" class="default-btn mb20 font_w_l cursor_p" style="float: right;border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;" (click)="toggle_projectLinkSubmission()">
                                <i class='bx bx-link icon-arrow before'></i>
                                <span class="label">Project Link Submission</span>
                                <i class="bx bx-chevron-down icon-arrow after"></i>
                            </button>

                            <div id="project_link_submission_div" class="cs-hide">
                                <div align="right">
                                    <button class="default-btn font_w_l cursor_p" style="border-top-left-radius: 20px;border-top-right-radius: 20px;" (click)="toggle_projectLinkSubmission()">
                                        <i class='bx bx-link icon-arrow before'></i>
                                        <span class="label">Project Link Submission</span>
                                        <i class="bx bx-chevron-up icon-arrow after"></i>
                                    </button>
                                </div>

                                <div class="cs-light-gray" style="border-radius: 50px 0px 50px 10px;">
                                    <form #urlForm="ngForm" (ngSubmit)="url_submit(urlForm.value)" *ngIf="!urlSubmitted">

                                        <div style="padding: 30px;">
                                            <div class="form-group">
                                                <input required ngModel name="url" type="url" #url="ngModel" [class.invalid_input]="url.touched && !url.valid" class="form-control" id="url" placeholder="Your Project Link..." pattern="https?://.+">
                                                <div class="err_msg" *ngIf="url.touched && !url.valid">
                                                    <div *ngIf="url.errors.required">Project Link is required.</div>
                                                    <div *ngIf="url.errors.pattern">Invalid Project Link !. - https://
                                                    </div>
                                                </div>
                                            </div>
                                            <br>
                                            <p><b>Note : </b> Double Check before you submit the url , you want be able to change your submission after submit.</p>
                                        </div>

                                        <div align="right">
                                            <button class="default-btn font_w_l cursor_p" style="border-radius: 50px;">
                                                <i class='bx bx-paper-plane icon-arrow before'></i>
                                                <span class="label">Submit</span>
                                                <i class="bx bx-paper-plane icon-arrow after"></i>
                                            </button>

                                        </div>
                                    </form>
                                    <div *ngIf="urlSubmitted">
                                        <div style="padding: 30px;">
                                            <div class="form-group">
                                                <input type="text" class="form-control" value="{{submittedURL}}" readonly style="color: gray;">
                                            </div>
                                            <br>
                                            <div align='center' *ngIf="!exam02Completed">
                                                <i class='bx bx-loader-alt cs-xxxlarge' style="color: var(--mainColor);"></i>
                                                <h2>Result Pending</h2>
                                                <p class="cs-small">You Have Submitted the Project Link <i class="bx bx-check-circle cs-text-green"></i></p>
                                                <p>Your Results Will be Released Soon!</p>
                                            </div>

                                            <div align='center' *ngIf="exam02Completed">
                                                <br>
                                                <div id="exam02ResultChart_div" style="width:300px;max-width:300px" class="cs-display-container">
                                                    <div class="cs-display-middle">
                                                        <h2>{{exam02resultDisplay}}</h2>
                                                    </div>
                                                </div>
                                                <br>
                                                <div>
                                                    <h2>You Have Completed the Program</h2>
                                                    <a class="cursor_p cs-hover-text-blue" href="learn-film-marking-with-dbima/dashboard">Get your Certificate Now ! <i class="bx bx-certification"></i></a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                            <br>

                            <h3>What you'll learn</h3>
                            <div class="why-you-learn">
                                <ul>
                                    <li>
                                        <span>
                                            <i class='bx bx-check'></i>
                                            Become an expert in Statistics
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i class='bx bx-check'></i>
                                            Boost your resume with skills
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i class='bx bx-check'></i>
                                            Gather, organize, data
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i class='bx bx-check'></i>
                                            Use data for improved
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i class='bx bx-check'></i>
                                            Present information KPIs
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i class='bx bx-check'></i>
                                            Perform quantitative
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i class='bx bx-check'></i>
                                            Analyze current data
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i class='bx bx-check'></i>
                                            Discover how to find trends
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i class='bx bx-check'></i>
                                            Understand the fundamentals
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i class='bx bx-check'></i>
                                            Use SQL to create, design
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <h3>Requirements</h3>
                            <ul class="requirements-list">
                                <li>Contrary to popular belief, Lorem Ipsum is not simply random text.</li>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.</li>
                                <li>The standard Lorem Ipsum passage, used since the 1500s.</li>
                            </ul>
                            <h3>Description</h3>
                            <p><strong>Hi! Welcome to Certified Graphic Design with Free Project Course, the only course
                                    you need to become a BI Analyst.</strong></p>
                            <p>We are proud to present you this one-of-a-kind opportunity. There are several online courses teaching some of the skills related to the BI Analyst profession. The truth of the matter is that none of them completely prepare
                                you.
                            </p>
                            <p><strong>Our program is different than the rest of the materials available
                                    online.</strong></p>
                            <p>It is truly comprehensive. The Business Intelligence Analyst Course comprises of several modules:
                            </p>
                            <ul class="description-features-list">
                                <li>Introduction to Data and Data Science</li>
                                <li>Statistics and Excel</li>
                                <li>Database theory</li>
                                <li>SQL</li>
                                <li>Tableau</li>
                            </ul>
                            <p>These are the precise technical skills recruiters are looking for when hiring BI Analysts. And today, you have the chance of acquiring an invaluable advantage to get ahead of other candidates. This course will be the secret
                                to your success. And your success is our success, so let’s make it happen!</p>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-lg-1"></div>
        </div>
    </div>
</div>