
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CountUpModule } from 'ngx-countup';
import { TabsModule } from 'ngx-tabset';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { StickyNavModule } from 'ng2-sticky-nav';
import { LightboxModule } from 'ngx-lightbox';
import { FormGroupName, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {MatSnackBar, MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSelectModule} from '@angular/material/select';

import { HttpClientModule } from '@angular/common/http';

//-----common----->>>>>>>>>
import { FooterComponent } from './components/common/footer/footer.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { HeaderStyleOneComponent } from './components/common/header-style-one/header-style-one.component';
import { CategoriesStyleOneComponent } from './components/common/categories-style-one/categories-style-one.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { PartnerStyleOneComponent } from './components/common/partner-style-one/partner-style-one.component';
import { InstructorsStyleOneComponent } from './components/common/instructors-style-one/instructors-style-one.component';
import { BecomeInstructorPartnerComponent } from './components/common/become-instructor-partner/become-instructor-partner.component';
import { FeedbackStyleOneComponent } from './components/common/feedback-style-one/feedback-style-one.component';
import { BlogComponent } from './components/common/blog/blog.component';
import { HeaderStyleTwoComponent } from './components/common/header-style-two/header-style-two.component';
import { OurMissionComponent } from './components/common/our-mission/our-mission.component';
import { InstructorsStyleTwoComponent } from './components/common/instructors-style-two/instructors-style-two.component';
import { WebinarCountdownComponent } from './components/common/webinar-countdown/webinar-countdown.component';
import { StudentsFeedbackFormComponent } from './components/common/students-feedback-form/students-feedback-form.component';
import { PartnerStyleTwoComponent } from './components/common/partner-style-two/partner-style-two.component';
import { OfferComponent } from './components/common/offer/offer.component';
import { CategoriesStyleTwoComponent } from './components/common/categories-style-two/categories-style-two.component';
import { FeedbackFormComponent } from './components/common/feedback-form/feedback-form.component';
import { HeaderStyleThreeComponent } from './components/common/header-style-three/header-style-three.component';
import { BoxesComponent } from './components/common/boxes/boxes.component';
import { FeedbackStyleTwoComponent } from './components/common/feedback-style-two/feedback-style-two.component';
import { InstructorsStyleThreeComponent } from './components/common/instructors-style-three/instructors-style-three.component';
import { FeaturesComponent } from './components/common/features/features.component';
import { CategoriesStyleThreeComponent } from './components/common/categories-style-three/categories-style-three.component';
import { HeaderStyleFourComponent } from './components/common/header-style-four/header-style-four.component';
import { HowItWorksComponent } from './components/common/how-it-works/how-it-works.component';
import { OurStoryComponent } from './components/common/our-story/our-story.component';
import { OurValuesComponent } from './components/common/our-values/our-values.component';

//-----Routs----->>>>>>>>>
import { HomeComponent } from './components/pages/home/home.component';
import { RegisterComponent } from './components/pages/auth/register/register.component';
import { LoginComponent } from './components/pages/auth/login/login.component';
import { HeaderComponent } from './components/common/header/header.component';
import { MainBannerComponent } from './components/pages/home/main-banner/main-banner.component';
import { ContactComponent } from './components/pages/company/contact/contact.component';
import { AuthValidatorComponent } from './components/other/auth-validator/auth-validator.component';
import { ErrorComponent } from './components/other/error/error.component';
import { MyDashboardComponent } from './components/pages/my-dashboard/my-dashboard.component';
import { ProgramComponent } from './components/pages/program/program.component';
import { ModuleComponent } from './components/pages/program/module/module.component';
import { LessonComponent } from './components/pages/program/lesson/lesson.component';
import { IframeLoaderComponent } from './components/layout/iframe-loader/iframe-loader.component';
import { QuizComponent } from './components/pages/program/quiz/quiz.component';
import { TutorialNavigationComponent } from './components/common/tutorial-navigation/tutorial-navigation.component';
import { ModuleNavigationComponent } from './components/common/module-navigation/module-navigation.component';
import { ExamOneComponent } from './components/pages/program/exam-one/exam-one.component';
import { ExamTwoComponent } from './components/pages/program/exam-two/exam-two.component';

//-----template----->>>>>>>>>
import { HomePageOneComponent } from './components/template/home-page-one/home-page-one.component';
import { HomePageTwoComponent } from './components/template/home-page-two/home-page-two.component';
import { HomeoneAboutComponent } from './components/template/home-page-one/homeone-about/homeone-about.component';
import { HomeoneMainBannerComponent } from './components/template/home-page-one/homeone-main-banner/homeone-main-banner.component';
import { HomeoneCoursesComponent } from './components/template/home-page-one/homeone-courses/homeone-courses.component';
import { HometwoMainBannerComponent } from './components/template/home-page-two/hometwo-main-banner/hometwo-main-banner.component';
import { HometwoAboutComponent } from './components/template/home-page-two/hometwo-about/hometwo-about.component';
import { HometwoCoursesComponent } from './components/template/home-page-two/hometwo-courses/hometwo-courses.component';
import { HomePageThreeComponent } from './components/template/home-page-three/home-page-three.component';
import { HomePageFourComponent } from './components/template/home-page-four/home-page-four.component';
import { HomePageFiveComponent } from './components/template/home-page-five/home-page-five.component';
import { HomePageSixComponent } from './components/template/home-page-six/home-page-six.component';
import { HomePageSevenComponent } from './components/template/home-page-seven/home-page-seven.component';
import { HomethreeCoursesComponent } from './components/template/home-page-three/homethree-courses/homethree-courses.component';
import { HomethreeAboutComponent } from './components/template/home-page-three/homethree-about/homethree-about.component';
import { HomethreeMainBannerComponent } from './components/template/home-page-three/homethree-main-banner/homethree-main-banner.component';
import { HomefourMainBannerComponent } from './components/template/home-page-four/homefour-main-banner/homefour-main-banner.component';
import { HomefourCoursesComponent } from './components/template/home-page-four/homefour-courses/homefour-courses.component';
import { HomefiveCoursesComponent } from './components/template/home-page-five/homefive-courses/homefive-courses.component';
import { HomefiveMainBannerComponent } from './components/template/home-page-five/homefive-main-banner/homefive-main-banner.component';
import { HomesixCoursesComponent } from './components/template/home-page-six/homesix-courses/homesix-courses.component';
import { HomesixMainBannerComponent } from './components/template/home-page-six/homesix-main-banner/homesix-main-banner.component';
import { HomesevenMainBannerComponent } from './components/template/home-page-seven/homeseven-main-banner/homeseven-main-banner.component';
import { HomesevenCoursesComponent } from './components/template/home-page-seven/homeseven-courses/homeseven-courses.component';
import { AboutPageOneComponent } from './components/template/about-page-one/about-page-one.component';
import { AboutPageTwoComponent } from './components/template/about-page-two/about-page-two.component';
import { InstructorsPageOneComponent } from './components/template/instructors-page-one/instructors-page-one.component';
import { InstructorsPageTwoComponent } from './components/template/instructors-page-two/instructors-page-two.component';
import { InstructorsPageThreeComponent } from './components/template/instructors-page-three/instructors-page-three.component';
import { InstructorsDetailsPageComponent } from './components/template/instructors-details-page/instructors-details-page.component';
import { GalleryPageComponent } from './components/template/gallery-page/gallery-page.component';
import { EventsPageComponent } from './components/template/events-page/events-page.component';
import { EventsDetailsPageComponent } from './components/template/events-details-page/events-details-page.component';
import { PricingPageComponent } from './components/template/pricing-page/pricing-page.component';
import { FeedbackPageComponent } from './components/template/feedback-page/feedback-page.component';
import { PartnerPageComponent } from './components/template/partner-page/partner-page.component';
import { LoginPageComponent } from './components/template/login-page/login-page.component';
import { RegisterPageComponent } from './components/template/register-page/register-page.component';
import { FaqPageComponent } from './components/template/faq-page/faq-page.component';
import { ErrorPageComponent } from './components/template/error-page/error-page.component';
import { ComingSoonPageComponent } from './components/template/coming-soon-page/coming-soon-page.component';
import { BlogGridPageComponent } from './components/template/blog-grid-page/blog-grid-page.component';
import { BlogRightSidebarPageComponent } from './components/template/blog-right-sidebar-page/blog-right-sidebar-page.component';
import { BlogFullWidthPageComponent } from './components/template/blog-full-width-page/blog-full-width-page.component';
import { BlogDetailsPageComponent } from './components/template/blog-details-page/blog-details-page.component';
import { ShopGridPageComponent } from './components/template/shop-grid-page/shop-grid-page.component';
import { ShopFullWidthPageComponent } from './components/template/shop-full-width-page/shop-full-width-page.component';
import { ProductsDetailsPageComponent } from './components/template/products-details-page/products-details-page.component';
import { CartPageComponent } from './components/template/cart-page/cart-page.component';
import { CheckoutPageComponent } from './components/template/checkout-page/checkout-page.component';
import { ContactPageComponent } from './components/template/contact-page/contact-page.component';
import { CategoryPageOneComponent } from './components/template/category-page-one/category-page-one.component';
import { CategoryPageTwoComponent } from './components/template/category-page-two/category-page-two.component';
import { CategoryPageThreeComponent } from './components/template/category-page-three/category-page-three.component';
import { CoursesListPageComponent } from './components/template/courses-list-page/courses-list-page.component';
import { CoursesGridStyleOneColumnsTwoPageComponent } from './components/template/courses-grid-style-one-columns-two-page/courses-grid-style-one-columns-two-page.component';
import { CoursesGridStyleOneColumnsThreePageComponent } from './components/template/courses-grid-style-one-columns-three-page/courses-grid-style-one-columns-three-page.component';
import { CoursesGridStyleOneColumnsFourPageComponent } from './components/template/courses-grid-style-one-columns-four-page/courses-grid-style-one-columns-four-page.component';
import { CoursesGridStyleTwoColumnsTwoPageComponent } from './components/template/courses-grid-style-two-columns-two-page/courses-grid-style-two-columns-two-page.component';
import { CoursesGridStyleTwoColumnsThreePageComponent } from './components/template/courses-grid-style-two-columns-three-page/courses-grid-style-two-columns-three-page.component';
import { CoursesGridStyleTwoColumnsFourPageComponent } from './components/template/courses-grid-style-two-columns-four-page/courses-grid-style-two-columns-four-page.component';
import { CoursesGridStyleThreeColumnsTwoPageComponent } from './components/template/courses-grid-style-three-columns-two-page/courses-grid-style-three-columns-two-page.component';
import { CoursesGridStyleThreeColumnsThreePageComponent } from './components/template/courses-grid-style-three-columns-three-page/courses-grid-style-three-columns-three-page.component';
import { CoursesGridStyleThreeColumnsFourPageComponent } from './components/template/courses-grid-style-three-columns-four-page/courses-grid-style-three-columns-four-page.component';
import { CoursesDetailsPageComponent } from './components/template/courses-details-page/courses-details-page.component';
import { MyDashboardPageComponent } from './components/template/my-dashboard-page/my-dashboard-page.component';
import { OrdersPageComponent } from './components/template/orders-page/orders-page.component';
import { DownloadsPageComponent } from './components/template/downloads-page/downloads-page.component';
import { EditAddressPageComponent } from './components/template/edit-address-page/edit-address-page.component';
import { EditAccountPageComponent } from './components/template/edit-account-page/edit-account-page.component';
import { EditBillingAddressPageComponent } from './components/template/edit-billing-address-page/edit-billing-address-page.component';
import { EditShippingAddressPageComponent } from './components/template/edit-shipping-address-page/edit-shipping-address-page.component';
import { CertificationVerifyComponent } from './components/pages/certification-verify/certification-verify.component';
import { DashboardComponent } from './components/pages/program/dashboard/dashboard.component';
import { FeedbackComponent } from './components/pages/company/feedback/feedback.component';
import { FeedbackDisplayComponent } from './components/common/feedback-display/feedback-display.component';
import { ForgotPasswordComponent } from './components/pages/auth/forgot-password/forgot-password.component';




@NgModule({
  declarations: [
    AppComponent,
    HomePageOneComponent,
    HomePageTwoComponent,
    FooterComponent,
    PreloaderComponent,
    HomeoneAboutComponent,
    HomeoneMainBannerComponent,
    HeaderStyleOneComponent,
    CategoriesStyleOneComponent,
    FunfactsComponent,
    HomeoneCoursesComponent,
    PartnerStyleOneComponent,
    InstructorsStyleOneComponent,
    BecomeInstructorPartnerComponent,
    FeedbackStyleOneComponent,
    BlogComponent,
    HeaderStyleTwoComponent,
    HometwoMainBannerComponent,
    HometwoAboutComponent,
    HometwoCoursesComponent,
    OurMissionComponent,
    InstructorsStyleTwoComponent,
    WebinarCountdownComponent,
    StudentsFeedbackFormComponent,
    HomePageThreeComponent,
    HomePageFourComponent,
    HomePageFiveComponent,
    HomePageSixComponent,
    HomePageSevenComponent,
    PartnerStyleTwoComponent,
    OfferComponent,
    CategoriesStyleTwoComponent,
    HomethreeCoursesComponent,
    HomethreeAboutComponent,
    FeedbackFormComponent,
    HomethreeMainBannerComponent,
    HeaderStyleThreeComponent,
    HomefourMainBannerComponent,
    HomefourCoursesComponent,
    BoxesComponent,
    FeedbackStyleTwoComponent,
    HomefiveCoursesComponent,
    HomefiveMainBannerComponent,
    InstructorsStyleThreeComponent,
    FeaturesComponent,
    HomesixCoursesComponent,
    CategoriesStyleThreeComponent,
    HomesixMainBannerComponent,
    HeaderStyleFourComponent,
    HomesevenMainBannerComponent,
    HomesevenCoursesComponent,
    HowItWorksComponent,
    AboutPageOneComponent,
    AboutPageTwoComponent,
    OurStoryComponent,
    OurValuesComponent,
    InstructorsPageOneComponent,
    InstructorsPageTwoComponent,
    InstructorsPageThreeComponent,
    InstructorsDetailsPageComponent,
    GalleryPageComponent,
    EventsPageComponent,
    EventsDetailsPageComponent,
    PricingPageComponent,
    FeedbackPageComponent,
    PartnerPageComponent,
    LoginPageComponent,
    RegisterPageComponent,
    FaqPageComponent,
    ErrorPageComponent,
    ComingSoonPageComponent,
    BlogGridPageComponent,
    BlogRightSidebarPageComponent,
    BlogFullWidthPageComponent,
    BlogDetailsPageComponent,
    ShopGridPageComponent,
    ShopFullWidthPageComponent,
    ProductsDetailsPageComponent,
    CartPageComponent,
    CheckoutPageComponent,
    ContactPageComponent,
    CategoryPageOneComponent,
    CategoryPageTwoComponent,
    CategoryPageThreeComponent,
    CoursesListPageComponent,
    CoursesGridStyleOneColumnsTwoPageComponent,
    CoursesGridStyleOneColumnsThreePageComponent,
    CoursesGridStyleOneColumnsFourPageComponent,
    CoursesGridStyleTwoColumnsTwoPageComponent,
    CoursesGridStyleTwoColumnsThreePageComponent,
    CoursesGridStyleTwoColumnsFourPageComponent,
    CoursesGridStyleThreeColumnsTwoPageComponent,
    CoursesGridStyleThreeColumnsThreePageComponent,
    CoursesGridStyleThreeColumnsFourPageComponent,
    CoursesDetailsPageComponent,
    MyDashboardPageComponent,
    OrdersPageComponent,
    DownloadsPageComponent,
    EditAddressPageComponent,
    EditAccountPageComponent,
    EditBillingAddressPageComponent,
    EditShippingAddressPageComponent,

    HomeComponent,
    RegisterComponent,
    LoginComponent,
    AuthValidatorComponent,
    ErrorComponent,
    HeaderComponent,
    MainBannerComponent,
    ContactComponent,
    MyDashboardComponent,
    ProgramComponent,
    ModuleComponent,
    LessonComponent,
    IframeLoaderComponent,
    QuizComponent,
    TutorialNavigationComponent,
    ModuleNavigationComponent,
    ExamOneComponent,
    ExamTwoComponent,
    CertificationVerifyComponent,
    DashboardComponent,
    FeedbackComponent,
    FeedbackDisplayComponent,
    ForgotPasswordComponent,
  ],
  imports: [
    MatSnackBarModule,
    MatSelectModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    CountUpModule,
    TabsModule,
    NgxScrollTopModule,
    StickyNavModule,
    LightboxModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  exports: [
    MatSnackBarModule,
    MatSelectModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
