<section class="courses-area ptb-100">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Discover Courses</span>
            <h2>Our Popular Online Courses</h2>
            <a routerLink="/courses-2-columns-style-3" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">All Courses</span><i class="bx bx-show-alt icon-arrow after"></i></a>
        </div>
        <div class="courses-slides">
            <owl-carousel-o [options]="coursesSlides">
                <ng-template carouselSlide>
                    <div class="single-courses-box without-box-shadow mb-30">
                        <div class="courses-image">
                            <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                            <div class="courses-tag">
                                <a routerLink="/" class="d-block">Business</a>
                            </div>
                        </div>
                        <div class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Steven Smith</span>
                            </div>
                            <h3><a routerLink="/single-courses" class="d-inline-block">Raque Professional IT Expert Certificate Course</a></h3>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <div class="rating-total">
                                    5.0 (1 rating)
                                </div>
                            </div>
                        </div>
                        <div class="courses-box-footer">
                            <ul>
                                <li class="students-number">
                                    <i class='bx bx-user'></i> 10 students
                                </li>
                                <li class="courses-lesson">
                                    <i class='bx bx-book-open'></i> 6 lessons
                                </li>
                                <li class="courses-price">
                                    Free
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-courses-box without-box-shadow mb-30">
                        <div class="courses-image">
                            <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                            <div class="courses-tag">
                                <a routerLink="/" class="d-block">Design</a>
                            </div>
                        </div>
                        <div class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Sarah Taylor</span>
                            </div>
                            <h3><a routerLink="/single-courses" class="d-inline-block">Certified Graphic Design with Free Project Course</a></h3>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star-half'></i>
                                </div>
                                <div class="rating-total">
                                    4.5 (2 rating)
                                </div>
                            </div>
                        </div>
                        <div class="courses-box-footer">
                            <ul>
                                <li class="students-number">
                                    <i class='bx bx-user'></i> 15 students
                                </li>
                                <li class="courses-lesson">
                                    <i class='bx bx-book-open'></i> 10 lessons
                                </li>
                                <li class="courses-price">
                                    $250
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-courses-box without-box-shadow mb-30">
                        <div class="courses-image">
                            <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses3.jpg" alt="image"></a>
                            <div class="courses-tag">
                                <a routerLink="/" class="d-block">Development</a>
                            </div>
                        </div>
                        <div class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <img src="assets/img/user3.jpg" class="rounded-circle mr-2" alt="image">
                                <span>James Anderson</span>
                            </div>
                            <h3><a routerLink="/single-courses" class="d-inline-block">Photography Crash Course for Photographer</a></h3>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bx-star'></i>
                                </div>
                                <div class="rating-total">
                                    4.0 (1 rating)
                                </div>
                            </div>
                        </div>
                        <div class="courses-box-footer">
                            <ul>
                                <li class="students-number">
                                    <i class='bx bx-user'></i> 5 students
                                </li>
                                <li class="courses-lesson">
                                    <i class='bx bx-book-open'></i> 5 lessons
                                </li>
                                <li class="courses-price">
                                    $150
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-courses-box without-box-shadow mb-30">
                        <div class="courses-image">
                            <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses4.jpg" alt="image"></a>
                            <div class="courses-tag">
                                <a routerLink="/" class="d-block">Language</a>
                            </div>
                        </div>
                        <div class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <img src="assets/img/user4.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Liam King</span>
                            </div>
                            <h3><a routerLink="/single-courses" class="d-inline-block">Web Design for Developing Technology with Joy</a></h3>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
        
                                <div class="rating-total">
                                    5.0 (1 rating)
                                </div>
                            </div>
                        </div>
                        <div class="courses-box-footer">
                            <ul>
                                <li class="students-number">
                                    <i class='bx bx-user'></i> 10 students
                                </li>
                                <li class="courses-lesson">
                                    <i class='bx bx-book-open'></i> 6 lessons
                                </li>
                                <li class="courses-price">
                                    <span>$200</span>
                                    $195
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-courses-box without-box-shadow mb-30">
                        <div class="courses-image">
                            <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses5.jpg" alt="image"></a>
                            <div class="courses-tag">
                                <a routerLink="/" class="d-block">Management</a>
                            </div>
                        </div>
                        <div class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <img src="assets/img/user5.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Lina D'Souza</span>
                            </div>
                            <h3><a routerLink="/single-courses" class="d-inline-block">Information About UI/UX Design Degree</a></h3>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <div class="rating-total">
                                    5.0 (1 rating)
                                </div>
                            </div>
                        </div>
                        <div class="courses-box-footer">
                            <ul>
                                <li class="students-number">
                                    <i class='bx bx-user'></i> 10 students
                                </li>
                                <li class="courses-lesson">
                                    <i class='bx bx-book-open'></i> 6 lessons
                                </li>
                                <li class="courses-price">
                                    $178
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-courses-box without-box-shadow mb-30">
                        <div class="courses-image">
                            <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses6.jpg" alt="image"></a>
                            <div class="courses-tag">
                                <a routerLink="/" class="d-block">Photography</a>
                            </div>
                        </div>
                        <div class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <img src="assets/img/user6.jpg" class="rounded-circle mr-2" alt="image">
                                <span>David Warner</span>
                            </div>
                            <h3><a routerLink="/single-courses" class="d-inline-block">Photography Photo modify and Beautiful</a></h3>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <div class="rating-total">
                                    5.0 (1 rating)
                                </div>
                            </div>
                        </div>
                        <div class="courses-box-footer">
                            <ul>
                                <li class="students-number">
                                    <i class='bx bx-user'></i> 10 students
                                </li>
                                <li class="courses-lesson">
                                    <i class='bx bx-book-open'></i> 6 lessons
                                </li>
                                <li class="courses-price">
                                    $500
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>