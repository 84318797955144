<app-header></app-header>
<br><br><br><br>

<div class="my-dashboard-area ptb-100">
    <div class="container">
        <div class="myDashboard-profile">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-5">
                    <div class="profile-image">
                        <div class="ImgContainer">
                            <img id="profileImage" *ngIf="userData.profileUrl == null || userData.profileUrl == '' || userData.profileUrl == 'null'" src="../../../../assets/img/illus/moments.svg" style="padding: 30px;" alt="image">
                            <img id="profileImage" *ngIf="userData.profileUrl != null && userData.profileUrl != '' && userData.profileUrl != 'null'" src="{{userData.profileUrl}}" style="padding: 30px;" alt="Profile Image" onerror="profilePicError(this)">
                            <div class="overlay">
                                <div class="text">
                                    <button class="cs-button" title="Select Profile Picture" onclick="modelShow('profilePicUploadModel')"><i
                                            class='bx bx-image-add'></i></button>
                                    <br>
                                    <button *ngIf="userData.profileUrl != null && userData.profileUrl != '' && userData.profileUrl != 'null'" class="cs-button" title="View Profile Picture" onclick="viewImgFullScreen('profileImage')"><i class='bx bx-show'></i></button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-7">
                    <div class="row">
                        <div class="col-6">
                            <div class="profile-content">
                                <h3>{{userData.firstName +" "+ userData.lastName}}</h3>
                                <p>{{userData.description}}</p>
                                <p *ngIf="userData.organization != null && userData.organization != ''">@ {{userData.organization}}
                                </p>
                                <ul class="contact-info">
                                    <li><i class='bx bx-envelope'></i> <a href="mailto:{{userData.email}}">{{userData.email}}</a></li>
                                    <li *ngIf="userData.phoneNumber != null && userData.phoneNumber != ''"><i class='bx bx-phone'></i> <a href="tel:+{{userData.phoneNumber}}">{{userData.phoneNumber}}</a></li>
                                </ul>
                                <ul class="social">
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a>
                                    </li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a>
                                    </li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a>
                                    </li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a>
                                    </li>
                                    <li><a href="#" class="d-block" target="_blank"><i
                                                class='bx bxl-pinterest-alt'></i></a>
                                    </li>
                                </ul>
                                <a routerLink="/" class="myDashboard-logout" (click)="logout()">Logout</a>
                            </div>
                        </div>
                        <div class="col-6 cs-display-container">
                            <div id="userProgressChart_div" style="width:300px;max-width:300px" class="cs-display-container cs-display-middle">
                                <div class="cs-display-middle">
                                    <h2>{{userProgressPercentage}}</h2>
                                </div>
                            </div>
                            <div class="cs-display-topright">
                                <div style="margin-right: 50px;margin-top: 20px;">
                                    <a routerLink="/give-us-your-feedback" style="margin-right: 30px;"><i
                                            class=" bx bx-comment-dots cs-xlarge cursor_p cs-text-gray cs_nav_icon_2"></i></a>
                                    <a href="learn-film-marking-with-dbima/dashboard"><i
                                            class='bx bx-grid-alt cs-xlarge cursor_p cs-text-gray cs_nav_icon'></i></a>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="myDashboard-navigation">
                        <ul>
                            <li style="float: right;"><button type="button" onclick="toggleView('account_details')" class="default-btn cs-right"><i class='bx bx-edit icon-arrow before'></i><span
                                        class="label" style="font-weight: lighter;">Account Details</span><i
                                        class="bx bx-edit icon-arrow after"></i></button></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>


        <div class="myDashboard-content cs-animate-zoom cs-hide" id="account_details">
            <form class="edit-account" #editAccountForm="ngForm" (ngSubmit)="submit(editAccountForm.value)">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="form-group">
                            <label>First name <span class="required">*</span> <i class="bx bxs-star"
                                    style="color: #F9A826;"></i></label>
                            <input [(ngModel)]="userData.firstName" required minlength="3" maxlength="50" ngModel name="fname" type="text" #fname="ngModel" [class.invalid_input]="fname.touched && !fname.valid" class="form-control" id="fname" placeholder="First Name" pattern="[a-zA-Z ]*">
                            <div class="err_msg" *ngIf="fname.touched && !fname.valid">
                                <div *ngIf="fname.errors.pattern">First Name cannot contain numbers.
                                </div>
                                <div *ngIf="fname.errors.required">First Name is required.</div>
                                <div *ngIf="fname.errors.minlength">First Name is minimum {{ fname.errors.minlength.requiredLength }} characters.</div>
                                <div *ngIf="fname.errors.maxlength">First Name is maximum 20 characters.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="form-group">
                            <label>Last name <span class="required">*</span> <i class="bx bxs-star"
                                    style="color: #F9A826;"></i></label>
                            <input [(ngModel)]="userData.lastName" required minlength="3" maxlength="50" ngModel name="lname" type="text" #lname="ngModel" [class.invalid_input]="lname.touched && !lname.valid" class="form-control" id="lname" placeholder="Last Name" pattern="[a-zA-Z ]*">
                            <div class="err_msg" *ngIf="lname.touched && !lname.valid">
                                <div *ngIf="lname.errors.pattern">Last Name cannot contain numbers.
                                </div>
                                <div *ngIf="lname.errors.required">Last Name is required.</div>
                                <div *ngIf="lname.errors.minlength">Last Name is minimum {{ lname.errors.minlength.requiredLength }} characters.</div>
                                <div *ngIf="lname.errors.maxlength">Last Name is maximum 20 characters.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="form-group">
                            <label>Date Of Birth <i class="bx bxs-star" style="color: #F9A826;"></i></label>
                            <input [(ngModel)]="userData.dob" type="date" ngModel name="dob" [class.invalid_input]="dob.touched && !dob.valid" #dob="ngModel" placeholder="Date Of Birth" class="form-control cursor_p" id="dob" max="{{today}}">
                            <div class="err_msg" *ngIf="dob.touched && !dob.valid">
                                <div>Invalid Date of Birth</div>
                            </div>
                        </div>
                        <!--description-->
                    </div>
                    <div class="col-md-6" [class.col-lg-4]="userData.type != ''" [class.col-lg-12]="userData.type == ''">
                        <div class="form-group">
                            <label>Role</label>
                            <div class="form-control cs-light-gray" style="padding-top: 12px;">
                                {{profileTypeName}}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6" *ngIf="userData.type == '6'">
                        <div class="form-group">
                            <label>Role Name <span class="required">*</span></label>
                            <input [(ngModel)]="userData.description" type="text" required ngModel name="role_name" #role_name="ngModel" [class.invalid_input]="role_name.touched && !role_name.valid" placeholder="Type your Role name ..." class="form-control" id="role_name">
                            <div class="err_msg" *ngIf="role_name.touched && !role_name.valid">Role name is required.
                            </div>
                        </div>
                        <!--description-->
                    </div>
                    <div class="col-md-6" [class.col-lg-8]="userData.type != '6'" [class.col-lg-4]="userData.type == '6'" *ngIf="userData.type != ''">
                        <div class="form-group">
                            <label>School/Establishment name <span class="required">*</span></label>
                            <input [(ngModel)]="userData.organization" type="text" required ngModel name="place" #place="ngModel" [class.invalid_input]="place.touched && !place.valid" placeholder="Type your School/Establishment name ..." class="form-control" id="place">
                            <div class="err_msg" *ngIf="place.touched && !place.valid">School/Establishment name is required.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Email address</label>
                            <div class="form-control cs-light-gray" style="padding-top: 12px;">
                                {{userData.email}}
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Phone number</label>
                            <input [(ngModel)]="userData.phoneNumber" ngModel name="phoneNumber" type="phoneNumber" #phoneNumber="ngModel" [class.invalid_input]="phoneNumber.touched && !phoneNumber.valid" class="form-control" id="phoneNumber" placeholder="Phone number" pattern="[0-9]*">
                            <div class="err_msg" *ngIf="phoneNumber.touched && !phoneNumber.valid">
                                <div *ngIf="phoneNumber.errors.pattern">Phone number cannot contain letters.</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Country</label>
                            <input [(ngModel)]="userData.country" ngModel name="country" type="text" #country="ngModel" [class.invalid_input]="country.touched && !country.valid" class="form-control" id="country" placeholder="Country" pattern="[a-zA-Z ]*">
                            <div class="err_msg" *ngIf="country.touched && !phoneNumber.valid">
                                <div *ngIf="country.errors.pattern">Country name cannot contain numbers.</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group" style="text-align: right;">
                            <p> <span class="required cs-xlarge" style="color: red;">*</span> Required fields</p>
                            <p> <i class="bx bxs-star" style="color: #F9A826;"></i> Required fields for Generate Certificate
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn" *ngIf="editAccountForm.valid"><i
                                class='bx bx-save icon-arrow before'></i><span class="label">Save Changes</span><i
                                class="bx bx-save icon-arrow after"></i></button>
                        <button type="submit" class="default-btn" *ngIf="!editAccountForm.valid" style="cursor: not-allowed;"><i class='bx bx-save icon-arrow before'></i><span
                                class="label">Save Changes </span><i class="bx bx-save icon-arrow after"></i></button>
                    </div>
                </div>
            </form>

            <button type="button" class="default-btn font_w_l" style="float: right;border-radius: 50px 0px 50px 50px;" onclick="modelShow('userPasswordChangeModel')">
                <i class='bx bx-link-external icon-arrow before'></i>
                <span class="label">CHANGE PASSWORD</span>
                <i class="bx bx-link-external icon-arrow after"></i>
            </button>

        </div>
    </div>
</div>


<!-- The Modals -->
<div id="profilePicUploadModel" class="cs-modal" style="z-index: 999;">
    <div class="cs-modal-content cs-animate-zoom cs-card cs-round cs-padding-16" style="z-index: 9999;width: fit-content;">
        <div class="cs-container">
            <div>
                <h3>Select Profile Picture</h3>
                <span onclick="modelClose('profilePicUploadModel')" class="cs-button cs-display-topright cs-large">&times;</span>
                <hr>
            </div>

            <div class="img-container cs-display-container" style="margin: 20px;padding: 0px;">

                <img id="img_preview" src="../../../../assets/img/illus/moments.svg" style="padding: 20px;">

                <div class="img-input cs-display-middle ">

                    <label class="cursor_p" for="img_preview_input">
                        <i class='bx bxs-image-add' style="font-size: 200px;"></i>
                    </label>

                    <input id="img_preview_input" type="file" class="cursor_p" (change)="profilePictureInputChange($event)" onchange="readURL(this, 'img_preview')" accept=".png, .jpg, .jpeg," />

                </div>
            </div>

            <div>
                <hr>
                <button type="submit" class="default-btn" style="font-weight: lighter;border-radius: 50px;" (click)="uploadProfilePicture()"><i class='bx bx-cloud-upload icon-arrow before'></i>
                    <span class="label" *ngIf="img_uploaded">Uploaded</span>
                    <span class="label" *ngIf="!img_uploaded && !upload_pending">Upload</span>
                    <span class="label" *ngIf="!img_uploaded && upload_pending">Uploading ...</span>
                    <i class="bx bx-cloud-upload icon-arrow after"></i></button>
            </div>

        </div>
    </div>
</div>

<div id="profilePicViewModel" class="cs-modal" style="z-index: 999;">
    <div class="cs-modal-content cs-animate-zoom cs-card cs-round cs-padding-16" style="z-index: 9999;width: fit-content;">
        <div class="cs-container">
            <span onclick="modelClose('profilePicViewModel')" class="cs-button cs-display-topright cs-large">&times;</span>
            <img id="full_img_view" src="../../../../assets/img/illus/moments.svg" style="padding: 20px;max-height: 700px;width: auto;">
        </div>
    </div>
</div>

<div id="userPasswordChangeModel" class="cs-modal" style="z-index: 999;">
    <div class="cs-modal-content cs-animate-zoom cs-card cs-round cs-padding-16" style="z-index: 9999;width: fit-content;">
        <div class="cs-container">
            <div>
                <h3>Change your password </h3>
                <span onclick="modelClose('userPasswordChangeModel')" class="cs-button cs-display-topright cs-large">&times;</span>
                <hr>
            </div>

            <form #userPwdUpdateForm="ngForm" (ngSubmit)='user_pwd_update(userPwdUpdateForm.value)'>
                <div class="row">
                    <div class="col-lg-12 col-md-12 cs-padding-16">
                        <div class="form-group">
                            <input required ngModel name="old_password" type="password" #old_password="ngModel" [class.invalid_input]="old_password.touched && !old_password.valid" class="form-control" id="confirm_password" placeholder="Your old password">
                            <div class="err_msg" *ngIf="old_password.touched && !old_password.valid">
                                Old password is required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 cs-padding-16">
                        <div class="form-group">
                            <input required minlength="6" maxlength="50" ngModel name="password" type="password" #password="ngModel" [class.invalid_input]="password.touched && !password.valid" class="form-control invalid_input" id="password" placeholder="Your new password">
                            <div class="err_msg" *ngIf="password.touched && !password.valid">
                                <div *ngIf="password.errors.required">Password is required.</div>
                                <div *ngIf="password.errors.minlength">Password minimum is {{ password.errors.minlength.requiredLength }} characters.</div>
                                <div *ngIf="password.errors.maxlength">Password maximum is {{ password.errors.maxlength.requiredLength }} characters.</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 cs-padding-16">
                        <div class="form-group">
                            <input required ngModel name="confirm_password" type="password" #confirm_password="ngModel" [class.invalid_input]="confirm_password.touched && !confirm_password.valid" class="form-control" id="confirm_password" placeholder="Confirm your new password">
                            <div class="err_msg" *ngIf="confirm_password.touched && !confirm_password.valid">Confirm your Password
                            </div>
                        </div>
                    </div>

                </div>

                <div>
                    <hr>
                    <button type="submit" class="default-btn font_w_l" style="float: right;border-radius: 0px 0px 20px 20px;" *ngIf="confirm_password.valid && password.valid && old_password.valid">
                        <div *ngIf="!change_pwd_req_pending">
                            <i class='bx bx-check-shield icon-arrow before'></i>
                            <span class="label">CHANGE PASSWORD</span>
                            <i class="bx bx-check-shield icon-arrow after"></i>
                        </div>
                        <div *ngIf="change_pwd_req_pending">
                            <i class='icon-arrow before'><i class="bx bx-loader-alt fa-spin"></i></i>
                            <span class="label">SENDING REQUEST</span>
                            <i class="icon-arrow after"><i class="bx bx-loader-alt fa-spin"></i></i>
                        </div>
                    </button>

                    <button type="button" class="font_w_l default-btn" style="float: right;border-radius: 0px 0px 20px 20px;cursor: not-allowed;" *ngIf="!confirm_password.valid || !password.valid || !old_password.valid">
                        <i class='bx bx-check-shield icon-arrow before'></i>
                        <span class="label">CHANGE PASSWORD</span>
                        <i class="bx bx-check-shield icon-arrow after"></i>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
