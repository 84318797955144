import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feedback-page',
  templateUrl: './feedback-page.component.html',
  styleUrls: ['./feedback-page.component.scss']
})
export class FeedbackPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
