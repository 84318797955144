import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import {
    getProfileData,
    userProfileDataUpdate,
    trackUserProgressState,
    fileUpload,
    updateProfilePictureURL,
    changePassword,
} from "../../../models/endpoints";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { UserClass } from "src/app/classes/UserClass";

declare var loadUserProgressChart;
@Component({
    selector: "app-my-dashboard",
    templateUrl: "./my-dashboard.component.html",
    styleUrls: ["./my-dashboard.component.scss"],
})
export class MyDashboardComponent implements OnInit {
    courseID = 6;

    userData = new UserClass();
    profile_id;
    profileTypeName;
    today;

    currentUserName: string;
    currentUserId: number;
    currentUserToken: string;
    currentUserProgressCourseID: number;
    currentUserProgressModuleID: number;
    currentUserProgressPartID: number;

    userProgressPercentage;

    imageFile;
    uploadText;
    upload_pending = false;
    img_uploaded = false;

    change_pwd_req_pending = false;

    constructor(
        private router: Router,
        private http: HttpClient,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit(): void {
        this.getCurrentUserData();
    }

    //------------------------------ User Data

    getCurrentUserData() {
        var userStatus = parseInt(localStorage.getItem("XJFYUserStatus"));
        var token = localStorage.getItem("XJFYToken");
        var userObj = JSON.parse(localStorage.getItem("XJFYUser"));

        if (userStatus == 1) {
            this.currentUserName = userObj.firstName + " " + userObj.lastName;
            this.currentUserId = userObj.id;
            this.currentUserToken = token;
            this.trackUserProgressState_function();
            this.loadData();
        } else {
            this.router.navigate(["error/404"]);
        }
    }

    trackUserProgressState_function() {
        this.http
            .get<any>(
                environment.baseUrl +
                    trackUserProgressState +
                    "userId=" +
                    this.currentUserId +
                    "&courseId=" +
                    this.courseID,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        localStorage.setItem(
                            "XJFYUserProgressCourseID",
                            data.courseId
                        );
                        localStorage.setItem(
                            "XJFYUserProgressModuleID",
                            data.tutorialId
                        );
                        localStorage.setItem(
                            "XJFYUserProgressPartID",
                            data.partId
                        );
                        this.currentUserProgressCourseID = data.courseId;
                        this.currentUserProgressModuleID = data.tutorialId;
                        this.currentUserProgressPartID = data.partId;
                        this.callToLoadUserProgressChart(
                            "userProgressChart",
                            parseInt(data.percentage)
                        );
                        this.userProgressPercentage = data.percentage + "%";
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    //---------------------------------------------------------------------------------------

    loadData() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();

        this.today = yyyy + "-" + mm + "-" + dd;

        var obj = JSON.parse(localStorage.getItem("XJFYUser"));
        this.profile_id = obj.id;

        this.http
            .get<any>(environment.baseUrl + getProfileData + this.profile_id, {
                headers: new HttpHeaders().set(
                    "Authorization",
                    localStorage.getItem("XJFYToken")
                ),
            })
            .subscribe({
                next: (data) => {
                    this.userData = data.user;
                    window.document.title =
                        this.userData.firstName +
                        " " +
                        this.userData.lastName +
                        " - Erasmus +";

                    if (this.userData.type == "4") {
                        this.profileTypeName = "Student";
                    } else if (this.userData.type == "5") {
                        this.profileTypeName = "Teacher";
                    } else if (this.userData.type == "6") {
                        this.profileTypeName = "Other";
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    submit(form) {
        this.http
            .post<any>(
                environment.baseUrl + userProfileDataUpdate,
                {
                    firstName: form.fname,
                    lastName: form.lname,
                    type: this.userData.type,
                    username: this.userData.email,
                    password: null,
                    email: this.userData.email,
                    phoneNumber: form.phoneNumber,
                    profileUrl: this.userData.profileUrl,
                    active: true,
                    description: form.role_name,
                    organization: form.place,
                    dob: form.dob,
                    country: form.country,
                    id: this.userData.id,
                },
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        localStorage.getItem("XJFYToken")
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.loadData();
                        this.openSnackBar(data.message, 1);
                        localStorage.setItem(
                            "XJFYUser",
                            JSON.stringify(data.user)
                        );
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    user_pwd_update(form) {
        if (form.password == form.confirm_password) {
            this.change_pwd_req_pending = true;
            this.http
                .post<any>(environment.baseUrl + changePassword, {
                    password: form.password,
                    oldPassword: form.old_password,
                    username: this.userData.email,
                    reset: false,
                })
                .subscribe({
                    next: (data) => {
                        if (data.status == 1) {
                            this.openSnackBar(
                                "Password Changed, login with your new credentials",
                                1
                            );
                            localStorage.clear();
                            this.router.navigate(["login"]);
                        } else {
                            this.openSnackBar(data.message, 1);
                        }
                        this.change_pwd_req_pending = false;
                    },
                    error: (error) => {
                        this.openSnackBar(
                            "There was an error! ," + error.message,
                            0
                        );
                        this.change_pwd_req_pending = false;
                    },
                });
        } else {
            this.openSnackBar("Password Mismatched", 1);
        }
    }

    logout() {
        localStorage.clear();
    }

    callToLoadUserProgressChart(id, percentage) {
        loadUserProgressChart(id, percentage);
    }

    uploadProfilePicture() {
        if (!this.img_uploaded) {
            this.upload_pending = true;
            var temp = this.imageFile.name.split(".");
            var filetype = temp[temp.length - 1];
            var fileName =
                "user-" + this.currentUserId + "-profile-picture." + filetype;

            const formData = new FormData();
            formData.append("file", this.imageFile, fileName);

            this.http
                .post<any>(environment.baseUrl + fileUpload, formData, {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                })
                .subscribe({
                    next: (data) => {
                        if (data.status == 1) {
                            this.updateProfilePictureURL_fun(data.path);
                        } else {
                            this.openSnackBar(data.message, 1);
                        }
                    },
                    error: (error) => {
                        this.openSnackBar(
                            "There was an error! ," + error.message,
                            0
                        );
                    },
                });
        } else {
            this.openSnackBar("This Image is Already uploaded !", 1);
        }
    }

    updateProfilePictureURL_fun(url: any) {
        this.http
            .get<any>(
                environment.baseUrl +
                    updateProfilePictureURL +
                    "id=" +
                    this.currentUserId +
                    "&url=" +
                    url,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.loadData();
                        this.openSnackBar(data.message, 1);
                        this.upload_pending = false;
                        this.img_uploaded = true;
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    profilePictureInputChange(fileInputEvent: any) {
        this.imageFile = fileInputEvent.target.files[0];
        if (this.imageFile.name != this.uploadText) {
            this.uploadText = this.imageFile.name;
            this.img_uploaded = false;
        }
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, "Dismiss", { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, "Dismiss");
        }
    }
}
