<div class="forgot-password-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="forgot-password-image">
                <img src="assets/img/auth-bg/forgot-password-bg.jpg" alt="image">
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
            <div class="forgot-password-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="forgot-password-form">
                            <div class="logo">
                                <a routerLink="/"><img src="assets/img/logo/site/logo_main.png" style="width: 100px;height: auto;" alt="image"></a>
                            </div>
                            <h3>Recover Password</h3>
                            <p>Remembered your password ? <a routerLink="/login">Login</a></p>


                            <form #fpSendOtpForm="ngForm">

                                <div *ngIf="fp_get_otp_show">

                                    <div class="form-group">
                                        <input required ngModel name="email" type="email" #email="ngModel" [class.invalid_input]="email.touched && !email.valid" [email]="true" class="form-control" id="email" placeholder="Your email address">
                                        <div class="err_msg" *ngIf="email.touched && !email.valid">
                                            <div *ngIf="email.errors.required">Email address is required.</div>
                                            <div *ngIf="!email.errors.required">Invalid email address</div>
                                        </div>
                                    </div>


                                    <button type="button" class="font_w_l forgot-password-btn" (click)="fp_send_otp(fpSendOtpForm.value)" *ngIf="email.valid">
                                        <span *ngIf="fp_get_otp_req_pending">SENDING REQUEST <i class="bx bx-loader-alt fa-spin ml10 cs-medium"></i></span>
                                        <span *ngIf="!fp_get_otp_req_pending">GET YOUR OTP CODE <i class="bx bx-paper-plane ml10 cs-large"></i></span>
                                    </button>

                                    <button type="button" class="font_w_l forgot-password-btn disabled-btn" *ngIf="!email.valid">
                                        GET YOUR OTP CODE <i class="bx bx-paper-plane ml10 cs-large"></i>
                                    </button>

                                </div>

                                <div *ngIf="fp_validate_otp_show">

                                    <div class="form-group">
                                        <input required ngModel name="otp_code" type="number" #otp_code="ngModel" [class.invalid_input]="otp_code.touched && !otp_code.valid" class="form-control" id="otp_code" placeholder="Your OTP CODE">
                                        <div class="err_msg" *ngIf="otp_code.touched && !otp_code.valid">
                                            <div *ngIf="otp_code.errors.required">OTP CODE is required.</div>
                                            <div *ngIf="!otp_code.errors.required">Invalid OTP CODE</div>
                                        </div>
                                    </div>

                                    <button type="button" class="font_w_l forgot-password-btn" (click)="fp_validate_otp(fpSendOtpForm.value)" *ngIf="otp_code.valid">
                                        <span *ngIf="fp_validate_otp_req_pending">SENDING REQUEST <i class="bx bx-loader-alt fa-spin ml10 cs-medium"></i></span>
                                        <span *ngIf="!fp_validate_otp_req_pending">CONFIRM <i class="bx bx-check-double ml10 cs-large"></i></span>
                                    </button>

                                    <button type="button" class="font_w_l forgot-password-btn disabled-btn" *ngIf="!otp_code.valid">
                                        CONFIRM <i class="bx bx-paper-plane ml10 cs-large"></i>
                                    </button>

                                </div>

                                <div *ngIf="fp_change_pwd_show">

                                    <div class="row">
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <input required minlength="6" maxlength="50" ngModel name="password" type="password" #password="ngModel" [class.invalid_input]="password.touched && !password.valid" class="form-control invalid_input" id="password" placeholder="New password">
                                                <div class="err_msg" *ngIf="password.touched && !password.valid">
                                                    <div *ngIf="password.errors.required">Password is required.</div>
                                                    <div *ngIf="password.errors.minlength">Password minimum is {{ password.errors.minlength.requiredLength }} characters.</div>
                                                    <div *ngIf="password.errors.maxlength">Password maximum is {{ password.errors.maxlength.requiredLength }} characters.</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <input required ngModel name="confirm_password" type="password" #confirm_password="ngModel" [class.invalid_input]="confirm_password.touched && !confirm_password.valid" class="form-control" id="confirm_password" placeholder="Confirm your new password">
                                                <div class="err_msg" *ngIf="confirm_password.touched && !confirm_password.valid">Confirm your Password
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <button type="button" class="font_w_l forgot-password-btn" (click)="fp_change_pwd(fpSendOtpForm.value)" *ngIf="confirm_password.valid && password.valid">
                                        <span *ngIf="fp_change_pwd_req_pending">SENDING REQUEST <i class="bx bx-loader-alt fa-spin ml10 cs-medium"></i></span>
                                        <span *ngIf="!fp_change_pwd_req_pending">CHANGE PASSWORD <i class="bx bx-check-shield ml10 cs-large"></i></span>
                                    </button>

                                    <button type="button" class="font_w_l forgot-password-btn disabled-btn" *ngIf="!confirm_password.valid || !password.valid">
                                        CHANGE PASSWORD <i class="bx bx-check-shield ml10 cs-large"></i>
                                    </button>

                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
