import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-account-page',
  templateUrl: './edit-account-page.component.html',
  styleUrls: ['./edit-account-page.component.scss']
})
export class EditAccountPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
