<div class="register-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="register-image">
                <img src="assets/img/auth-bg/register-bg.jpg" alt="image">
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
            <div class="register-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="register-form">
                            <div class="logo">
                                <a routerLink="/"><img src="assets/img/logo/site/logo_main.png" style="width: 100px;height: auto;" alt="image"></a>
                            </div>
                            <h3 style="line-height: 38px !important">Open up your Cagliero <span style="white-space: nowrap;">E-Cademy</span> Account now</h3>
                            <p>Already Registered? <a routerLink="/login">Log in</a></p>

                            <form #registerForm="ngForm" (ngSubmit)="submit(registerForm.value)">
                                <div class="row">
                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group">
                                            <input required minlength="3" maxlength="50" ngModel name="fname" type="text" #fname="ngModel" [class.invalid_input]="fname.touched && !fname.valid" class="form-control" id="fname" placeholder="First Name" pattern="[a-zA-Z ]*">
                                            <div class="err_msg" *ngIf="fname.touched && !fname.valid">
                                                <div *ngIf="fname.errors.pattern">First Name cannot contain numbers.
                                                </div>
                                                <div *ngIf="fname.errors.required">First Name is required.</div>
                                                <div *ngIf="fname.errors.minlength">First Name is minimum {{ fname.errors.minlength.requiredLength }} characters.</div>
                                                <div *ngIf="fname.errors.maxlength">First Name is maximum 20 characters.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group">
                                            <input required minlength="3" maxlength="50" ngModel name="lname" type="text" #lname="ngModel" [class.invalid_input]="lname.touched && !lname.valid" class="form-control" id="lname" placeholder="Last Name" pattern="[a-zA-Z ]*">
                                            <div class="err_msg" *ngIf="lname.touched && !lname.valid">
                                                <div *ngIf="lname.errors.pattern">Last Name cannot contain numbers.
                                                </div>
                                                <div *ngIf="lname.errors.required">Last Name is required.</div>
                                                <div *ngIf="lname.errors.minlength">Last Name is minimum {{ lname.errors.minlength.requiredLength }} characters.</div>
                                                <div *ngIf="lname.errors.maxlength">Last Name is maximum 20 characters.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12" [class.col-lg-4]="role.value != ''" [class.col-lg-12]="role.value == ''">
                                        <div class="form-group">
                                            <select required ngModel name="role" #role="ngModel" [class.invalid_input]="role.touched && !role.valid" class="form-control" id="role" style="cursor: pointer;">
                                                <option selected disabled value="">Select your role</option>
                                                <option value="4">Student</option>
                                                <option value="5">Teacher</option>
                                                <option value="6">Other</option>
                                            </select>
                                            <div class="err_msg" *ngIf="role.touched && !role.valid">
                                                <div *ngIf="role.errors.required">Role is required.</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-8 col-md-12" *ngIf="role.value == 6">
                                        <div class="form-group">
                                            <input type="text" required ngModel name="role_name" #role_name="ngModel" [class.invalid_input]="role_name.touched && !role_name.valid" placeholder="Type your Role name ..." class="form-control" id="role_name">
                                            <div class="err_msg" *ngIf="role_name.touched && !role_name.valid">Role name is required.</div>
                                        </div>
                                    </div>

                                    <div class="col-md-12" [class.col-lg-8]="role.value != 6" [class.col-lg-12]="role.value == 6" *ngIf="role.value != ''">
                                        <div class="form-group">
                                            <input type="text" required ngModel name="place" #place="ngModel" [class.invalid_input]="place.touched && !place.valid" placeholder="Type your School/Establishment name ..." class="form-control" id="place">
                                            <div class="err_msg" *ngIf="place.touched && !place.valid">
                                                School/Establishment name is required.</div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <input required ngModel name="email" type="email" #email="ngModel" [class.invalid_input]="email.touched && !email.valid" [email]="true" class="form-control" id="email" placeholder="Email address">
                                            <div class="err_msg" *ngIf="email.touched && !email.valid">
                                                <div *ngIf="email.errors.required">Email address is required.</div>
                                                <div *ngIf="!email.errors.required">Invalid email address</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group">
                                            <input required minlength="6" maxlength="50" ngModel name="password" type="password" #password="ngModel" [class.invalid_input]="password.touched && !password.valid" class="form-control invalid_input" id="password" placeholder="Password">
                                            <div class="err_msg" *ngIf="password.touched && !password.valid">
                                                <div *ngIf="password.errors.required">Password is required.</div>
                                                <div *ngIf="password.errors.minlength">Password minimum is {{ password.errors.minlength.requiredLength }} characters.</div>
                                                <div *ngIf="password.errors.maxlength">Password maximum is {{ password.errors.maxlength.requiredLength }} characters.</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group">
                                            <input required ngModel name="confirm_password" type="password" #confirm_password="ngModel" [class.invalid_input]="confirm_password.touched && !confirm_password.valid" class="form-control" id="confirm_password" placeholder="Confirm your password">
                                            <div class="err_msg" *ngIf="confirm_password.touched && !confirm_password.valid">Confirm your Password
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" *ngIf="!registerForm.valid" disabled>Register</button>
                                        <button type="submit" *ngIf="registerForm.valid">Register</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
