export class TutorialClass {
    id: number;
    courseId: number;
    tutorialName: string;
    tutorialOverview: string;
    languageCode: string;
    tutorialDurationMinute: number;
    tutorialDurationSecond: number;
    noOfParts: number;
}
