export class UserClass {
    id: number;
    firstName: string;
    lastName: string;
    type: string;
    username: string;
    password: string;
    email: string;
    phoneNumber: string;
    profileUrl: string;
    active: boolean;
    description: string;
    organization: string;
    dob: string;
    country: string;
}
