<div class="offer-area pt-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>What We Offer For Growth</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-offer-box">
                    <div class="icon">
                        <i class='bx bx-book-reader'></i>
                    </div>
                    <h3>Exclusive Advisor</h3>
                    <p>Lorem ipsum dolor sit amet cons ecttu adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-offer-box">
                    <div class="icon">
                        <i class='bx bx-target-lock'></i>
                    </div>
                    <h3>Reached Your Goals</h3>
                    <p>Lorem ipsum dolor sit amet cons ecttu adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-offer-box">
                    <div class="icon">
                        <i class='bx bxs-thermometer'></i>
                    </div>
                    <h3>Digital Laboratory</h3>
                    <p>Lorem ipsum dolor sit amet cons ecttu adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                </div>
            </div>
        </div>
    </div>
</div>