import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
@Component({
    selector: "app-error",
    templateUrl: "./error.component.html",
    styleUrls: ["./error.component.scss"],
})
export class ErrorComponent implements OnInit {
    url_data;

    error_header;
    error_body;

    constructor(private activatedRoute: ActivatedRoute) {
        this.activatedRoute.params.subscribe(
            (params) => (this.url_data = params)
        );
    }

    ngOnInit(): void {
        var error_code = this.url_data.error_code;
        if (error_code == 404) {
            this.error_header = "Error 404 : Page Not Found";
            this.error_body =
                "The page you are looking for might have been removed had its name changed or is temporarily unavailable.";
        } else if (error_code == 401) {
            this.error_header = "Error 401 : Unauthorized";
            this.error_body =
                "Sorry, you don't have the access to the page you are trying to access";
        } else {
            this.error_header = "Error 404 : Page Not Found";
            this.error_body =
                "The page you are looking for might have been removed had its name changed or is temporarily unavailable.";
        }
    }
}
