export class CourseClass {
    id: number;
    courseName: string;
    courseOverview: string;
    courseLevel: string;
    courseFee: string;
    courseDurationMinute: number;
    courseDurationSecond: number;
    noOfModules: number;
    courseLogoUrl: string;
    languageCode: string;
}
