import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

//-----Routs----->>>>>>>>>
import { HomeComponent } from "./components/pages/home/home.component";
import { LoginComponent } from "./components/pages/auth/login/login.component";
import { RegisterComponent } from "./components/pages/auth/register/register.component";
import { ContactComponent } from "./components/pages/company/contact/contact.component";
import { ErrorComponent } from "./components/other/error/error.component";
import { MyDashboardComponent } from "./components/pages/my-dashboard/my-dashboard.component";
import { ProgramComponent } from "./components/pages/program/program.component";
import { DashboardComponent } from './components/pages/program/dashboard/dashboard.component';
import { ModuleComponent } from "./components/pages/program/module/module.component";
import { LessonComponent } from "./components/pages/program/lesson/lesson.component";
import { QuizComponent } from './components/pages/program/quiz/quiz.component';
import { ExamOneComponent } from './components/pages/program/exam-one/exam-one.component';
import { ExamTwoComponent } from './components/pages/program/exam-two/exam-two.component';
import { IframeLoaderComponent } from './components/layout/iframe-loader/iframe-loader.component';
import { CertificationVerifyComponent } from "./components/pages/certification-verify/certification-verify.component";
import { FeedbackComponent } from './components/pages/company/feedback/feedback.component';
import { ForgotPasswordComponent } from './components/pages/auth/forgot-password/forgot-password.component';

//-----template----->>>>>>>>>
import { HomePageOneComponent } from "./components/template/home-page-one/home-page-one.component";
import { HomePageTwoComponent } from "./components/template/home-page-two/home-page-two.component";
import { HomePageThreeComponent } from "./components/template/home-page-three/home-page-three.component";
import { HomePageFourComponent } from "./components/template/home-page-four/home-page-four.component";
import { HomePageFiveComponent } from "./components/template/home-page-five/home-page-five.component";
import { HomePageSixComponent } from "./components/template/home-page-six/home-page-six.component";
import { HomePageSevenComponent } from "./components/template/home-page-seven/home-page-seven.component";
import { AboutPageOneComponent } from "./components/template/about-page-one/about-page-one.component";
import { AboutPageTwoComponent } from "./components/template/about-page-two/about-page-two.component";
import { InstructorsPageOneComponent } from "./components/template/instructors-page-one/instructors-page-one.component";
import { InstructorsPageTwoComponent } from "./components/template/instructors-page-two/instructors-page-two.component";
import { InstructorsPageThreeComponent } from "./components/template/instructors-page-three/instructors-page-three.component";
import { InstructorsDetailsPageComponent } from "./components/template/instructors-details-page/instructors-details-page.component";
import { EventsPageComponent } from "./components/template/events-page/events-page.component";
import { EventsDetailsPageComponent } from "./components/template/events-details-page/events-details-page.component";
import { GalleryPageComponent } from "./components/template/gallery-page/gallery-page.component";
import { PricingPageComponent } from "./components/template/pricing-page/pricing-page.component";
import { FeedbackPageComponent } from "./components/template/feedback-page/feedback-page.component";
import { PartnerPageComponent } from "./components/template/partner-page/partner-page.component";
import { LoginPageComponent } from "./components/template/login-page/login-page.component";
import { RegisterPageComponent } from "./components/template/register-page/register-page.component";
import { FaqPageComponent } from "./components/template/faq-page/faq-page.component";
import { ErrorPageComponent } from "./components/template/error-page/error-page.component";
import { ComingSoonPageComponent } from "./components/template/coming-soon-page/coming-soon-page.component";
import { BlogGridPageComponent } from "./components/template/blog-grid-page/blog-grid-page.component";
import { BlogRightSidebarPageComponent } from "./components/template/blog-right-sidebar-page/blog-right-sidebar-page.component";
import { BlogFullWidthPageComponent } from "./components/template/blog-full-width-page/blog-full-width-page.component";
import { BlogDetailsPageComponent } from "./components/template/blog-details-page/blog-details-page.component";
import { ShopGridPageComponent } from "./components/template/shop-grid-page/shop-grid-page.component";
import { ShopFullWidthPageComponent } from "./components/template/shop-full-width-page/shop-full-width-page.component";
import { ProductsDetailsPageComponent } from "./components/template/products-details-page/products-details-page.component";
import { CartPageComponent } from "./components/template/cart-page/cart-page.component";
import { CheckoutPageComponent } from "./components/template/checkout-page/checkout-page.component";
import { ContactPageComponent } from "./components/template/contact-page/contact-page.component";
import { CategoryPageOneComponent } from "./components/template/category-page-one/category-page-one.component";
import { CategoryPageTwoComponent } from "./components/template/category-page-two/category-page-two.component";
import { CategoryPageThreeComponent } from "./components/template/category-page-three/category-page-three.component";
import { CoursesListPageComponent } from "./components/template/courses-list-page/courses-list-page.component";
import { CoursesGridStyleOneColumnsTwoPageComponent } from "./components/template/courses-grid-style-one-columns-two-page/courses-grid-style-one-columns-two-page.component";
import { CoursesGridStyleOneColumnsThreePageComponent } from "./components/template/courses-grid-style-one-columns-three-page/courses-grid-style-one-columns-three-page.component";
import { CoursesGridStyleOneColumnsFourPageComponent } from "./components/template/courses-grid-style-one-columns-four-page/courses-grid-style-one-columns-four-page.component";
import { CoursesGridStyleTwoColumnsTwoPageComponent } from "./components/template/courses-grid-style-two-columns-two-page/courses-grid-style-two-columns-two-page.component";
import { CoursesGridStyleTwoColumnsThreePageComponent } from "./components/template/courses-grid-style-two-columns-three-page/courses-grid-style-two-columns-three-page.component";
import { CoursesGridStyleTwoColumnsFourPageComponent } from "./components/template/courses-grid-style-two-columns-four-page/courses-grid-style-two-columns-four-page.component";
import { CoursesGridStyleThreeColumnsFourPageComponent } from "./components/template/courses-grid-style-three-columns-four-page/courses-grid-style-three-columns-four-page.component";
import { CoursesGridStyleThreeColumnsThreePageComponent } from "./components/template/courses-grid-style-three-columns-three-page/courses-grid-style-three-columns-three-page.component";
import { CoursesGridStyleThreeColumnsTwoPageComponent } from "./components/template/courses-grid-style-three-columns-two-page/courses-grid-style-three-columns-two-page.component";
import { CoursesDetailsPageComponent } from "./components/template/courses-details-page/courses-details-page.component";
import { MyDashboardPageComponent } from "./components/template/my-dashboard-page/my-dashboard-page.component";
import { OrdersPageComponent } from "./components/template/orders-page/orders-page.component";
import { DownloadsPageComponent } from "./components/template/downloads-page/downloads-page.component";
import { EditAddressPageComponent } from "./components/template/edit-address-page/edit-address-page.component";
import { EditAccountPageComponent } from "./components/template/edit-account-page/edit-account-page.component";
import { EditBillingAddressPageComponent } from "./components/template/edit-billing-address-page/edit-billing-address-page.component";
import { EditShippingAddressPageComponent } from "./components/template/edit-shipping-address-page/edit-shipping-address-page.component";


const routes: Routes = [
    { path: "", component: HomeComponent },
    { path: "home", component: HomeComponent },
    { path: "login", component: LoginComponent },
    { path: "register", component: RegisterComponent },
    { path: "contact", component: ContactComponent },
    { path: "my-dashboard", component: MyDashboardComponent },
    { path: "learn-film-marking-with-dbima", component: ProgramComponent },
    {
        path: "learn-film-marking-with-dbima/dashboard",
        component: DashboardComponent,
    },
    {
        path: "learn-film-marking-with-dbima/module/:m_module_id",
        component: ModuleComponent,
    },
    {
        path: "learn-film-marking-with-dbima/module/:l_module_id/lesson/:l_lesson_id",
        component: LessonComponent,
    },
    {
        path: "learn-film-marking-with-dbima/module/:q_module_id/lesson/:q_lesson_id/quiz",
        component: QuizComponent,
    },
    {
        path: "learn-film-marking-with-dbima/exam-mcq",
        component: ExamOneComponent,
    },
    {
        path: "learn-film-marking-with-dbima/exam-project",
        component: ExamTwoComponent,
    },
    {
        path: "certification-verify",
        component: CertificationVerifyComponent,
    },
    { path: "layout/iframe-loader", component: IframeLoaderComponent },
    { path: "error/:error_code", component: ErrorComponent },
    { path: "give-us-your-feedback", component: FeedbackComponent },

    { path: "forgot-password", component: ForgotPasswordComponent },

    { path: "course-portal-online", component: HomePageTwoComponent },
    { path: "distant-learning", component: HomePageThreeComponent },
    { path: "modern-teaching", component: HomePageFourComponent },
    { path: "online-schooling", component: HomePageFiveComponent },
    { path: "learning-school-online", component: HomePageSixComponent },
    { path: "remote-training-online", component: HomePageSevenComponent },
    { path: "about-style-1", component: AboutPageOneComponent },
    { path: "about-style-2", component: AboutPageTwoComponent },
    { path: "instructor-1", component: InstructorsPageOneComponent },
    { path: "instructor-2", component: InstructorsPageTwoComponent },
    { path: "instructor-3", component: InstructorsPageThreeComponent },
    { path: "single-instructor", component: InstructorsDetailsPageComponent },
    { path: "gallery", component: GalleryPageComponent },
    { path: "events", component: EventsPageComponent },
    { path: "single-events", component: EventsDetailsPageComponent },
    { path: "pricing", component: PricingPageComponent },
    { path: "feedback", component: FeedbackPageComponent },
    { path: "partner", component: PartnerPageComponent },
    { path: "faqs", component: FaqPageComponent },
    { path: "error-404", component: ErrorPageComponent },
    { path: "coming-soon", component: ComingSoonPageComponent },
    { path: "courses-category-style-1", component: CategoryPageOneComponent },
    { path: "courses-category-style-2", component: CategoryPageTwoComponent },
    { path: "courses-category-style-3", component: CategoryPageThreeComponent },
    { path: "courses-list", component: CoursesListPageComponent },
    {
        path: "courses-2-columns-style-1",
        component: CoursesGridStyleOneColumnsTwoPageComponent,
    },
    {
        path: "courses-3-columns-style-1",
        component: CoursesGridStyleOneColumnsThreePageComponent,
    },
    {
        path: "courses-4-columns-style-1",
        component: CoursesGridStyleOneColumnsFourPageComponent,
    },
    {
        path: "courses-2-columns-style-2",
        component: CoursesGridStyleTwoColumnsTwoPageComponent,
    },
    {
        path: "courses-3-columns-style-2",
        component: CoursesGridStyleTwoColumnsThreePageComponent,
    },
    {
        path: "courses-4-columns-style-2",
        component: CoursesGridStyleTwoColumnsFourPageComponent,
    },
    {
        path: "courses-2-columns-style-3",
        component: CoursesGridStyleThreeColumnsTwoPageComponent,
    },
    {
        path: "courses-3-columns-style-3",
        component: CoursesGridStyleThreeColumnsThreePageComponent,
    },
    {
        path: "courses-4-columns-style-3",
        component: CoursesGridStyleThreeColumnsFourPageComponent,
    },
    { path: "single-courses", component: CoursesDetailsPageComponent },
    { path: "my-dashboard-2", component: MyDashboardPageComponent },
    { path: "orders", component: OrdersPageComponent },
    { path: "downloads", component: DownloadsPageComponent },
    { path: "edit-address", component: EditAddressPageComponent },
    { path: "edit-account", component: EditAccountPageComponent },
    {
        path: "edit-billing-address",
        component: EditBillingAddressPageComponent,
    },
    {
        path: "edit-shipping-address",
        component: EditShippingAddressPageComponent,
    },
    { path: "blog-style-1", component: BlogGridPageComponent },
    { path: "blog-style-2", component: BlogRightSidebarPageComponent },
    { path: "blog-style-3", component: BlogFullWidthPageComponent },
    { path: "single-blog", component: BlogDetailsPageComponent },
    { path: "shop-grid", component: ShopGridPageComponent },
    { path: "shop-grid-fullwidth", component: ShopFullWidthPageComponent },
    { path: "single-products", component: ProductsDetailsPageComponent },
    { path: "cart", component: CartPageComponent },
    { path: "checkout", component: CheckoutPageComponent },

    { path: "**", component: ErrorComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
