import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-main-banner",
    templateUrl: "./main-banner.component.html",
    styleUrls: ["./main-banner.component.scss"],
})
export class MainBannerComponent implements OnInit {
    currentUserName: string;
    currentUserId: number;
    currentUserToken: string;
    constructor() {}

    ngOnInit(): void {
        this.getCurrentUserData();
    }

    getCurrentUserData() {
        var userStatus = parseInt(localStorage.getItem("XJFYUserStatus"));
        var token = localStorage.getItem("XJFYToken");
        var userObj = JSON.parse(localStorage.getItem("XJFYUser"));

        if (userStatus == 1) {
            this.currentUserName = userObj.firstName + " " + userObj.lastName;
            this.currentUserId = userObj.id;
            this.currentUserToken = token;
        } else {
        }
    }
}
