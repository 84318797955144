<app-header></app-header>
<br><br><br><br>


<div class="feedback-form-area" style="margin-top: 200px;margin-bottom: 200px;">
    <div class="container">
        <div class="feedback-form-inner">
            <div class="row">
                <div class="col-lg-5 col-md-12">
                    <div class="feedback-form-image">
                        <img src="assets/img/system/certification-verify.png" alt="image" style="width:300px;height:auto;transform: rotate(-10deg);">
                    </div>
                </div>
                <div class="col-lg-7 col-md-12">
                    <div class="feedback-form">
                        <h3 class="cs-light-gray">Verify Your Certification</h3>
                        <form #certificationVerifyForm="ngForm" (ngSubmit)="onsubmit(certificationVerifyForm.value)">
                            <div class="row">
                                <div class="col-lg-6 m-auto">
                                    <div class="form-group">
                                        <input type="text" required ngModel name="certification_id" #certification_id="ngModel" [class.invalid_input]="certification_id.touched && !certification_id.valid" [class.invalid_input]="invalid_id" (click)="invalid_id = false" placeholder="Certification ID*"
                                            class="form-control" id="certification_id">
                                        <div class="err_msg">
                                            <div *ngIf="certification_id.touched && !certification_id.valid">
                                                Certification ID is required.</div>
                                            <div *ngIf="invalid_id">Certification ID is Invalid !!!.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <button type="button" class="default-btn" *ngIf="!certification_id.valid && !submitPending" style="cursor: not-allowed;"><i class='bx bx-send icon-arrow before'></i><span
                                            class="label">Submit</span><i
                                            class="bx bx-send icon-arrow after"></i></button>
                                    <button type="submit" class="default-btn" *ngIf="certification_id.valid && !submitPending"><i
                                            class='bx bx-send icon-arrow before'></i><span class="label">Submit</span><i
                                            class="bx bx-send icon-arrow after"></i></button>
                                    <button *ngIf="certification_id.valid && submitPending" type="button" class="default-btn">
                                        <i class='icon-arrow before'><i class="bx bx-loader-alt fa-spin "></i></i>
                                        <span class="label">Validating</span>
                                        <i class="icon-arrow after"><i class="bx bx-loader-alt fa-spin "></i></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container" *ngIf="verified">
        <div class="row">
            <div class="col-lg-5 col-md-12" style="background-color: transparent;">

            </div>
            <div class="col-lg-7 col-md-12">
                <div class="cs-card cs-animate-zoom" style="padding: 30px;">
                    <div class="feedback-form" align='center'>
                        <div>
                            <div class="row" style="margin-top: 20px;user-select: none;">
                                <div class="col-6 m-auto">
                                    <img src="assets/img/system/verified.jpg" alt="image" style="width:150px;height:auto;-webkit-user-drag: none;">
                                </div>
                                <div class="col-6 m-auto">
                                    <div class="row" align='right'>
                                        <h2>{{verified_userCourseName}}</h2>
                                        <span>{{verified_userIssuedData}}</span>
                                        <hr>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <img src="{{verified_userProfilePicURL}}" alt="image" class=" cs-border cs-border-light-gray" style="width:fit-content;height:auto;-webkit-user-drag: none;" onerror="this.src='assets/img/logo/site/logo.png'">
                                        </div>
                                        <div class="col-8" align='left'>
                                            <h3><b>{{verified_userName}}</b></h3>
                                            <p class="cs-small">
                                                {{verified_userLocation}} <br> {{verified_userDOB}}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>
