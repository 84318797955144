<app-header></app-header>
<br><br><br><br>
<app-main-banner></app-main-banner>

<!--
<div class="funfacts-area pt-100">
    <div class="container">
        <div class="funfacts-inner">
            <app-funfacts></app-funfacts>
            <div id="particles-js-circle-bubble"></div>
        </div>
    </div>
</div>

<div class="courses-categories-area pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Courses Categories</span>
            <h2>Browse Trending Categories</h2>
            <a routerLink="/courses-category-style-2" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">View All</span><i class="bx bx-show-alt icon-arrow after"></i></a>
        </div>
        <app-categories-style-two></app-categories-style-two>
    </div>
</div>
<app-become-instructor-partner></app-become-instructor-partner>
-->

<div class="testimonials-area pt-100 pb-70">
    <app-feedback-display></app-feedback-display>
</div>

<!--
<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Explore News</span>
            <h2>Our Latest Insights</h2>
            <a routerLink="/blog-style-1" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">Read All</span><i class="bx bx-book-reader icon-arrow after"></i></a>
        </div>
        <app-blog></app-blog>
    </div>
</div>-->