<div class="hero-banner bg-white">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-banner-content black-color">
                            <!--style="margin-bottom: 32px;"-->
                            <span class="sub-title">Creative skills for young minds</span>
                            <h1 style="margin-bottom: 32px;">Basics of Filmmaking</h1>
                            <h1 style="margin-bottom: 32px;">for the modern</h1>
                            <h1 style="margin-bottom: 32px;">digital World!</h1>
                            <p>Learn filmmaking from the industry experts and get certified from Don Bosco International Media Academy.
                            </p>
                            <div class="btn-box">
                                <a routerLink="/learn-film-marking-with-dbima" class="default-btn"><i
                                        class='bx bx-paper-plane icon-arrow before'></i><span class="label">Start
                                        Tutorials</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                                <a routerLink="/register" class="optional-btn" *ngIf="currentUserName == ' ' || currentUserName == null">Register Now
                                    {{currentUserName}}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-banner-image text-center">
                            <!--assets/img/banner/home_banner.jpg-->
                            <img src="assets/img/banner-img3.jpg" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
