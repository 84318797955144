import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import {
    getAllModuleData,
    getUserStateOfProgramRegistration,
    projectURLSubmissionCheck,
    trackUserProgressState,
    getCertificate,
    getProfileValidate,
} from "../../../../models/endpoints";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { CourseClass } from "src/app/classes/CourseClass";

declare var loadExamResultChart;
declare var loadUserProgressChart;

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
    firstTimeInProgram;

    courseID = 6;
    language;

    courseData = new CourseClass();
    tutorialAllData;
    tutorialData;

    currentUserName: string;
    currentUserId: number;
    currentUserToken: string;
    currentUserProgressCourseID: number;
    currentUserProgressModuleID: number;
    currentUserProgressPartID: number;

    all_quiz_completed = false;
    exam01Completed = false;
    exam02Completed = false;
    urlSubmitted = false;
    submittedURL;
    allCompleted = false;

    autoSaveInterval;
    exam01result;
    exam02result;
    exam01resultDisplay;
    exam02resultDisplay;
    fullResultDisplay;

    userProgressPercentage;
    profileDataMissing = true;
    downloadPending =false;

    constructor(
        private router: Router,
        private http: HttpClient,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit(): void {
        this.getCurrentUserData();
        if (this.currentUserName != null) {
            this.getUserStateOfProgramRegistration_function();
        }
        this.loadData();
    }

    getCurrentUserData() {
        var userStatus = parseInt(localStorage.getItem("XJFYUserStatus"));
        var token = localStorage.getItem("XJFYToken");
        var userObj = JSON.parse(localStorage.getItem("XJFYUser"));

        if (userStatus == 1) {
            this.currentUserName = userObj.firstName + " " + userObj.lastName;
            this.currentUserId = userObj.id;
            this.currentUserToken = token;
        }
    }

    getUserStateOfProgramRegistration_function() {
        this.http
            .get<any>(
                environment.baseUrl +
                    getUserStateOfProgramRegistration +
                    this.currentUserId,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.firstTimeInProgram = false;
                        this.trackUserProgressState_function();
                    } else {
                        this.firstTimeInProgram = true;
                        this.router.navigate(["/error/401"]);
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    loadData() {
        this.language = "E";
        this.http
            .get<any>(
                environment.baseUrl +
                    getAllModuleData +
                    "language=" +
                    this.language +
                    "&courseId=" +
                    this.courseID,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    this.courseData = data.course;
                    this.tutorialAllData = data.tutorialDtos;

                    var tutorialData = new Array();

                    for (
                        let index = 0;
                        index < this.tutorialAllData.length;
                        index++
                    ) {
                        tutorialData[index] =
                            this.tutorialAllData[index].tutorial;
                    }

                    this.tutorialData = tutorialData;

                    var tnpDataArray = new Array();
                    var tnpDataIndex = 0;

                    for (
                        let index = 0;
                        index < this.tutorialAllData.length;
                        index++
                    ) {
                        var pDataArray = this.tutorialAllData[index].parts;
                        this.tutorialAllData[index].tutorial.unlocked = false;

                        for (
                            let index2 = 0;
                            index2 < pDataArray.length;
                            index2++
                        ) {
                            tnpDataArray[tnpDataIndex] = {
                                t_id: this.tutorialAllData[index].tutorial.id,
                                p_id: pDataArray[index2].id,
                                unlocked: false,
                            };

                            this.tutorialAllData[index].parts[index2].unlocked =
                                false;

                            tnpDataIndex++;
                        }
                    }

                    var indexOfLastUnlockedTNP;

                    for (let index = 0; index < tnpDataArray.length; index++) {
                        tnpDataArray[index];
                        if (
                            tnpDataArray[index].t_id ==
                                this.currentUserProgressModuleID &&
                            tnpDataArray[index].p_id ==
                                this.currentUserProgressPartID
                        ) {
                            indexOfLastUnlockedTNP = index;
                        }
                    }

                    for (
                        let index = 0;
                        index <= indexOfLastUnlockedTNP;
                        index++
                    ) {
                        tnpDataArray[index].unlocked = true;

                        for (
                            let index2 = 0;
                            index2 < this.tutorialAllData.length;
                            index2++
                        ) {
                            var this_tutorialData =
                                this.tutorialAllData[index2].tutorial;
                            var this_partData =
                                this.tutorialAllData[index2].parts;
                            for (
                                let index3 = 0;
                                index3 < this_partData.length;
                                index3++
                            ) {
                                if (
                                    tnpDataArray[index].t_id ==
                                        this_tutorialData.id &&
                                    tnpDataArray[index].p_id ==
                                        this_partData[index3].id
                                ) {
                                    this_partData[index3].unlocked = true;
                                    this_tutorialData.unlocked = true;
                                }
                            }
                        }
                    }

                    this.getProfileValidate_fun();

                    window.document.title =
                        this.courseData.courseName + " - Erasmus +";
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    trackUserProgressState_function() {
        this.http
            .get<any>(
                environment.baseUrl +
                    trackUserProgressState +
                    "userId=" +
                    this.currentUserId +
                    "&courseId=" +
                    this.courseID,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        localStorage.setItem(
                            "XJFYUserProgressCourseID",
                            data.courseId
                        );
                        localStorage.setItem(
                            "XJFYUserProgressModuleID",
                            data.tutorialId
                        );
                        localStorage.setItem(
                            "XJFYUserProgressPartID",
                            data.partId
                        );
                        this.currentUserProgressCourseID = data.courseId;
                        this.currentUserProgressModuleID = data.tutorialId;
                        this.currentUserProgressPartID = data.partId;

                        this.firstTimeInProgram = false;

                        if (data.percentage == 100) {
                            this.loadProgressChart(data.percentage);
                            this.all_quiz_completed = true;
                            this.loadData();

                            if (!data.exam01) {
                            } else {
                                this.exam01Completed = true;

                                this.projectURLSubmissionCheck_fun();
                                if (!data.exam02) {
                                } else {
                                    this.exam02Completed = true;
                                    this.allCompleted = true;

                                    this.exam02result = data.result02;
                                    this.autoSaveInterval = setTimeout(() => {
                                        this.callToLoadExamResultChart(
                                            "exam02ResultChart",
                                            this.exam02result,
                                            40 - this.exam02result
                                        );
                                        this.exam02resultDisplay =
                                            "" + this.exam02result + "/40";
                                    }, 1000);

                                    this.autoSaveInterval = setTimeout(() => {
                                        this.callToLoadExamResultChart(
                                            "fullResultChart",
                                            this.exam01result +
                                                this.exam02result,
                                            100 -
                                                (this.exam01result +
                                                    this.exam02result)
                                        );
                                        this.fullResultDisplay =
                                            "" +
                                            (this.exam01result +
                                                this.exam02result) +
                                            "/100";
                                    }, 1000);
                                }

                                this.exam01result = data.result01;
                                this.autoSaveInterval = setTimeout(() => {
                                    this.callToLoadExamResultChart(
                                        "exam01ResultChart",
                                        this.exam01result,
                                        60 - this.exam01result
                                    );
                                    this.exam01resultDisplay =
                                        "" + this.exam01result + "/60";
                                }, 1000);
                            }
                        } else {
                            this.loadProgressChart(data.percentage);
                            this.loadData();
                        }
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    projectURLSubmissionCheck_fun() {
        this.http
            .get<any>(
                environment.baseUrl +
                    projectURLSubmissionCheck +
                    "userId=" +
                    this.currentUserId +
                    "&courseId=" +
                    this.courseID,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.urlSubmitted = true;
                        this.submittedURL = data.message;
                    } else {
                        this.urlSubmitted = false;
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    callToLoadExamResultChart(id, correct, incorrect) {
        loadExamResultChart(id, correct, incorrect);
    }

    callToLoadUserProgressChart(id, percentage) {
        loadUserProgressChart(id, percentage);
    }

    loadProgressChart(percentage) {
        this.callToLoadUserProgressChart(
            "modules_userProgressChart",
            parseInt(percentage)
        );
        this.callToLoadUserProgressChart(
            "lessons_userProgressChart",
            parseInt(percentage)
        );
        this.callToLoadUserProgressChart(
            "quizzes_userProgressChart",
            parseInt(percentage)
        );

        this.userProgressPercentage = percentage + "%";
    }

    getProfileValidate_fun() {
        this.http
            .get<any>(
                environment.baseUrl + getProfileValidate + this.currentUserId,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.profileDataMissing = false;
                    }else{
                        this.profileDataMissing = true;
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    getYourCertificate() {
        this.downloadPending = true;
        this.http
            .get<Blob>(
                environment.baseUrl +
                    getCertificate +
                    "userId=" +
                    this.currentUserId +
                    "&courseId=" +
                    this.courseID,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                    responseType: "blob" as "json",
                }
            )
            .subscribe(
                (data: Blob) => {
                    const file = new Blob([data], { type: "application/pdf" });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.downloadPending = false;
                },
                (error) => {
                    if (error.status != 0) {
                        this.openSnackBar(
                            "Your request can't be completed right now.",
                            1
                        );
                    }
                    this.downloadPending = false;
                }
            );
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, "Dismiss", { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, "Dismiss");
        }
    }
}
