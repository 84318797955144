import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { getAllModuleData, getTNPIdList } from "../../../../models/endpoints";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router, ActivatedRoute } from "@angular/router";
import { CourseClass } from "src/app/classes/CourseClass";
import { TutorialClass } from "src/app/classes/TutorialClass";

@Component({
    selector: "app-module",
    templateUrl: "./module.component.html",
    styleUrls: ["./module.component.scss"],
})
export class ModuleComponent implements OnInit {
    url_data;

    courseID = 6;
    language;
    moduleID;

    courseData = new CourseClass();
    tutorialData = new TutorialClass();
    tutorialAllData;
    partData;

    partCount;

    tnpData;

    thisLastUnlockedLesson;
    thisPageIsUnlocked = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private http: HttpClient,
        private snackBar: MatSnackBar
    ) {
        this.activatedRoute.params.subscribe(
            (params) => (this.url_data = params)
        );
    }

    ngOnInit(): void {
        this.moduleID = this.url_data.m_module_id;
        this.accessCheckForThisPage();
    }

    //------------------------------ Access Validators

    accessCheckForThisPage() {
        this.http
            .get<any>(environment.baseUrl + getTNPIdList, {
                headers: new HttpHeaders().set(
                    "Authorization",
                    localStorage.getItem("XJFYToken")
                ),
            })
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.tnpData = data.idList;
                        console.log(this.tnpData);
                        this.validateUserAccess();
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    validateUserAccess() {
        var tnpDataArray = this.tnpData;
        var LastUnlockedModule = parseInt(
            localStorage.getItem("XJFYUserProgressModuleID")
        );

        var LastUnlockedLesson = parseInt(
            localStorage.getItem("XJFYUserProgressPartID")
        );

        this.thisLastUnlockedLesson = LastUnlockedLesson;
        var indexOfLastUnlockedTNP;

        for (let index = 0; index < tnpDataArray.length; index++) {
            tnpDataArray[index];
            if (
                tnpDataArray[index].tutorialId == LastUnlockedModule &&
                tnpDataArray[index].partId == LastUnlockedLesson
            ) {
                indexOfLastUnlockedTNP = index;
            }
        }

        for (let index = 0; index <= indexOfLastUnlockedTNP; index++) {
            tnpDataArray[index].unlocked = true;
            if (tnpDataArray[index].tutorialId == this.moduleID) {
                this.thisPageIsUnlocked = true;
            }
        }

        if (!this.thisPageIsUnlocked) {
            this.router.navigate(["error/401"]);
        } else {
            this.loadData();
        }
    }

    //---------------------------------------------------------------------------------------

    loadData() {
        this.language = "E";

        this.http
            .get<any>(
                environment.baseUrl +
                    getAllModuleData +
                    "language=" +
                    this.language +
                    "&courseId=" +
                    this.courseID,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        localStorage.getItem("XJFYToken")
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    this.courseData = data.course;
                    this.tutorialAllData = data.tutorialDtos;

                    for (
                        let index = 0;
                        index < this.tutorialAllData.length;
                        index++
                    ) {
                        if (
                            this.tutorialAllData[index].tutorial.id ==
                            this.moduleID
                        ) {
                            this.partData = this.tutorialAllData[index].parts;
                            this.tutorialData =
                                this.tutorialAllData[index].tutorial;
                            window.document.title =
                                this.tutorialData.tutorialName + " - Erasmus +";
                        }
                    }

                    this.partCount = this.partData.length;
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, "Dismiss", { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, "Dismiss");
        }
    }

    toggle_transcript() {
        var transcript_toggle_btn = document.getElementById(
            "transcript_toggle_btn"
        );
        if (transcript_toggle_btn.innerText == "VIEW TRANSCRIPT") {
            transcript_toggle_btn.innerText = "HIDE TRANSCRIPT";
        } else {
            transcript_toggle_btn.innerText = "VIEW TRANSCRIPT";
        }

        document.getElementById("transcript").classList.toggle("cs-hide");
    }
}
