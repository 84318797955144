<app-header-style-three></app-header-style-three>

<div class="page-title-area page-title-style-two item-bg4">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Blog</li>
            </ul>
            <h2>Blog Right Sidebar</h2>
        </div>
    </div>
</div>

<div class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post mb-30">
                            <div class="post-image">
                                <a routerLink="/single-blog" class="d-block">
                                    <img src="assets/img/blog/blog1.jpg" alt="image">
                                </a>
                                <div class="tag">
                                    <a routerLink="/blog-style-2">Learning</a>
                                </div>
                            </div>
                            <div class="post-content">
                                <ul class="post-meta">
                                    <li class="post-author">
                                        <img src="assets/img/user1.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                                        By: <a routerLink="/blog-style-2" class="d-inline-block">Steven Smith</a>
                                    </li>
                                    <li><a routerLink="/blog-style-2">August 30, 2019</a></li>
                                </ul>
                                <h3><a routerLink="/single-blog" class="d-inline-block">World largest elephant toothpaste experiment in 2019</a></h3>
                                <a routerLink="/single-blog" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post mb-30">
                            <div class="post-image">
                                <a routerLink="/single-blog" class="d-block">
                                    <img src="assets/img/blog/blog2.jpg" alt="image">
                                </a>
                                <div class="tag">
                                    <a routerLink="/blog-style-2">Education</a>
                                </div>
                            </div>
                            <div class="post-content">
                                <ul class="post-meta">
                                    <li class="post-author">
                                        <img src="assets/img/user2.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                                        By: <a routerLink="/blog-style-2" class="d-inline-block">Lina D'Souja</a>
                                    </li>
                                    <li><a routerLink="/blog-style-2">August 29, 2019</a></li>
                                </ul>
                                <h3><a routerLink="/single-blog" class="d-inline-block">Most Popular Education Posts Of The Week 20-26 Aug</a></h3>
                                <a routerLink="/single-blog" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post mb-30">
                            <div class="post-image">
                                <a routerLink="/single-blog" class="d-block">
                                    <img src="assets/img/blog/blog3.jpg" alt="image">
                                </a>
                                <div class="tag">
                                    <a routerLink="/blog-style-2">Management</a>
                                </div>
                            </div>
                            <div class="post-content">
                                <ul class="post-meta">
                                    <li class="post-author">
                                        <img src="assets/img/user3.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                                        By: <a routerLink="/blog-style-2" class="d-inline-block">David Malan</a>
                                    </li>
                                    <li><a routerLink="/blog-style-2">August 28, 2019</a></li>
                                </ul>
                                <h3><a routerLink="/single-blog" class="d-inline-block">How to enhance education quality management system</a></h3>
                                <a routerLink="/single-blog" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post mb-30">
                            <div class="post-image">
                                <a routerLink="/single-blog" class="d-block">
                                    <img src="assets/img/blog/blog4.jpg" alt="image">
                                </a>
                                <div class="tag">
                                    <a routerLink="/blog-style-2">Ideas</a>
                                </div>
                            </div>
                            <div class="post-content">
                                <ul class="post-meta">
                                    <li class="post-author">
                                        <img src="assets/img/user5.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                                        By: <a routerLink="/blog-style-2" class="d-inline-block">David Warner</a>
                                    </li>
                                    <li><a routerLink="/blog-style-2">August 27, 2019</a></li>
                                </ul>
                                <h3><a routerLink="/single-blog" class="d-inline-block">Global education: Ideas for the way move forward</a></h3>
                                <a routerLink="/single-blog" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post mb-30">
                            <div class="post-image">
                                <a routerLink="/single-blog" class="d-block">
                                    <img src="assets/img/blog/blog5.jpg" alt="image">
                                </a>
                                <div class="tag">
                                    <a routerLink="/blog-style-2">Workforce</a>
                                </div>
                            </div>
                            <div class="post-content">
                                <ul class="post-meta">
                                    <li class="post-author">
                                        <img src="assets/img/user6.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                                        By: <a routerLink="/blog-style-2" class="d-inline-block">Olivar Waise</a>
                                    </li>
                                    <li><a routerLink="/blog-style-2">August 26, 2019</a></li>
                                </ul>
                                <h3><a routerLink="/single-blog" class="d-inline-block">New report reimagines the broader education workforce</a></h3>
                                <a routerLink="/single-blog" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post mb-30">
                            <div class="post-image">
                                <a routerLink="/single-blog" class="d-block">
                                    <img src="assets/img/blog/blog6.jpg" alt="image">
                                </a>

                                <div class="tag">
                                    <a routerLink="/blog-style-2">Education</a>
                                </div>
                            </div>
                            <div class="post-content">
                                <ul class="post-meta">
                                    <li class="post-author">
                                        <img src="assets/img/user2.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                                        By: <a routerLink="/blog-style-2" class="d-inline-block">Lina D'Souja</a>
                                    </li>
                                    <li><a routerLink="/blog-style-2">August 29, 2019</a></li>
                                </ul>
                                <h3><a routerLink="/single-blog" class="d-inline-block">What’s Going On in This Picture? | Jan. 13, 2020</a></h3>
                                <a routerLink="/single-blog" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post mb-30">
                            <div class="post-image">
                                <a routerLink="/single-blog" class="d-block">
                                    <img src="assets/img/blog/blog7.jpg" alt="image">
                                </a>
    
                                <div class="tag">
                                    <a routerLink="/blog-style-2">Learning</a>
                                </div>
                            </div>
                            <div class="post-content">
                                <ul class="post-meta">
                                    <li class="post-author">
                                        <img src="assets/img/user1.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                                        By: <a routerLink="/blog-style-2" class="d-inline-block">Steven Smith</a>
                                    </li>
                                    <li><a routerLink="/blog-style-2">August 30, 2019</a></li>
                                </ul>
                                <h3><a routerLink="/single-blog" class="d-inline-block">Connecting Math and Science to Reading and Writing</a></h3>
                                <a routerLink="/single-blog" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post mb-30">
                            <div class="post-image">
                                <a routerLink="/single-blog" class="d-block">
                                    <img src="assets/img/blog/blog8.jpg" alt="image">
                                </a>
                                <div class="tag">
                                    <a routerLink="/blog-style-2">Education</a>
                                </div>
                            </div>
                            <div class="post-content">
                                <ul class="post-meta">
                                    <li class="post-author">
                                        <img src="assets/img/user2.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                                        By: <a routerLink="/blog-style-2" class="d-inline-block">Lina D'Souja</a>
                                    </li>
                                    <li><a routerLink="/blog-style-2">August 29, 2019</a></li>
                                </ul>
                                <h3><a routerLink="/single-blog" class="d-inline-block">How to Introduce Meditation to the High School</a></h3>
                                <a routerLink="/single-blog" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="pagination-area text-center">
                            <span class="page-numbers current" aria-current="page">1</span>
                            <a routerLink="/blog-style-2" class="page-numbers">2</a>
                            <a routerLink="/blog-style-2" class="page-numbers">3</a>
                            <a routerLink="/blog-style-2" class="page-numbers">4</a>
                            <a routerLink="/blog-style-2" class="page-numbers">5</a>
                            <a routerLink="/blog-style-2" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <div class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </div>
                    <div class="widget widget_ednuv_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>
                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">June 10, 2019</time>
                                <h4 class="title usmall"><a routerLink="/single-blog">Making Peace With The Feast Or Famine Of Freelancing</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">June 21, 2019</time>
                                <h4 class="title usmall"><a routerLink="/single-blog">I Used The Web For A Day On A 50 MB Budget</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span class="fullimage cover bg3" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">June 30, 2019</time>
                                <h4 class="title usmall"><a routerLink="/single-blog">How To Create A Responsive Popup Gallery?</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </div>
                    <div class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>
                        <ul>
                            <li><a routerLink="/blog-style-2">Design <span class="post-count">(03)</span></a></li>
                            <li><a routerLink="/blog-style-2">Lifestyle <span class="post-count">(05)</span></a></li>
                            <li><a routerLink="/blog-style-2">Script <span class="post-count">(10)</span></a></li>
                            <li><a routerLink="/blog-style-2">Device <span class="post-count">(08)</span></a></li>
                            <li><a routerLink="/blog-style-2">Tips <span class="post-count">(01)</span></a></li>
                        </ul>
                    </div>
                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Ednuv Tags</h3>
                        <div class="tagcloud">
                            <a routerLink="/blog-style-2">IT <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-style-2">Raque <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-style-2">Games <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-style-2">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-style-2">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-style-2">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-style-2">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-style-2">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </div>
                    <div class="widget widget_instagram">
                        <h3 class="widget-title">Instagram</h3>
                        <ul>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog1.jpg" alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog2.jpg" alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog3.jpg" alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog4.jpg" alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog5.jpg" alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog6.jpg" alt="image"></a></li>
                        </ul>
                    </div>
                    <div class="widget widget_contact">
                        <div class="text">
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <span>Emergency</span>
                            <a href="tel:+098798768753">+0987-9876-8753</a>
                        </div>
                    </div>
                </aside>
            </div>
        </div>
    </div>
</div>