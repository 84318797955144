<app-header></app-header>
<br><br><br><br>

<div class="courses-details-area pt-100 pb-70">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <div class="courses-title">
                        <div class="row">
                            <div class="col-10">
                                <h2 routerLink="/learn-film-marking-with-dbima" class="cursor_p">Film Marking With DBIMA
                                </h2>
                                <h4 (click)="Dummy_checkAllAnswers()">Exam 01</h4>
                            </div>
                            <div class="col-2" style="text-align: right;">
                                <a href="learn-film-marking-with-dbima/dashboard"><i
                                        class='bx bx-grid-alt cs-xlarge cursor_p cs-text-gray cs_nav_icon'
                                        style="margin-top: 20px;"></i></a>
                            </div>
                        </div>

                        <br>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">

            <div class="col-lg-4">
                <div class="courses-sidebar-information">
                    <h4>Module Overview</h4>
                    <ul>
                        <li>
                            <div onclick="toggleView('lessons')" class="cursor_p">
                                <span><i class='bx bx-book'></i> Exams:</span> 0 /2
                            </div>

                            <br><br>

                            <div class="cs-animate-zoom" id="lessons">

                                <div>
                                    <div class="cs-button cs-block cs-padding-16 cs-round row cs-light-gray" style="margin-left: 5px;cursor: default;">
                                        <div class="col-10 cs-left-align">
                                            <label>Exam 01</label>
                                        </div>
                                        <div class="col-2">
                                            <span><i class="bx cs-xlarge" [class.bx-loader-alt]="!exam01Completed"
                                                    [class.bx-check-double]="exam01Completed"></i></span>
                                        </div>
                                    </div>

                                    <div *ngIf="!exam01Completed">
                                        <div class="cs-padding-16 cs-round row cs-medium cs-animate-zoom part_detail_card" style="margin-left: 50px;margin-right: 5px;">

                                            <div class="cursor_p cs-padding-16" align='left'>

                                                <div class="cs-button cs-round" style="margin: 1px;" *ngFor="let quiz of quizData ; let i = index" [class.cs-blue]="currentlyViewingQuizID == quiz.id" id="navBtn_{{quiz.id}}" (click)="switchQuestion(quiz.id)">
                                                    <label *ngIf="i+1<10">0{{i+1}}</label>
                                                    <label *ngIf="i+1>=10">{{i+1}}</label>
                                                </div>

                                            </div>
                                        </div>

                                        <button *ngIf="answersCount!=30 && !answersValidated" disabled class="default-btn mb20 font_w_l cs-right" style="border-radius: 50px;font-weight: lighter;width: 125px;cursor: not-allowed;">
                                            <i class='bx bx-paper-plane icon-arrow before'></i>
                                            <span class="label">Submit</span>
                                            <i class="bx bx-paper-plane icon-arrow after"></i>
                                        </button>

                                        <button *ngIf="answersCount==30 && !answersValidated" class="default-btn mb20 font_w_l cursor_p cs-right" style="border-radius: 50px;font-weight: lighter;width: 125px;" (click)="validateAnswers()">
                                            <i class='bx bx-paper-plane icon-arrow before'></i>
                                            <span class="label">Submit</span>
                                            <i class="bx bx-paper-plane icon-arrow after"></i>
                                        </button>
                                    </div>



                                    <div class="cs-button cs-block cs-padding-16 cs-round row cs-hover-pale-blue" style="margin-left: 5px;cursor: default;" *ngIf="!exam01Completed">
                                        <div class="col-10 cs-left-align">
                                            <label class="cs-text-gray ">Exam 02</label>
                                        </div>
                                        <div class="col-2">
                                            <span><i class="bx bx-lock cs-xlarge"></i></span>
                                        </div>
                                    </div>

                                    <a class="cs-button cs-block cs-padding-16 cs-round row cursor_p" style="margin-left: 5px;" href="learn-film-marking-with-dbima/exam-project" *ngIf="exam01Completed">
                                        <div class="col-10 cs-left-align">
                                            <label>Exam 02</label>
                                        </div>
                                        <div class="col-2">
                                            <span><i class="bx cs-xlarge" [class.bx-check-double]="exam02Completed"
                                                    [class.bx-loader-alt]="!exam02Completed"></i></span>
                                        </div>
                                    </a>

                                </div>
                            </div>

                        </li>

                        <li>
                            <span><i class='bx bx-atom'></i> Quizzes:</span> <i class="bx bx-check-double cs-xlarge cs-text-green"></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-1"></div>

            <div class="col-lg-6">
                <div class="courses-details-image cs-card">
                    <div id="Quiz" *ngIf="!exam01Completed">
                        <div style="padding: 30px;">

                            <div *ngFor="let quiz of quizData ; let i = index">
                                <div [class.cs-hide]="i != 0" id="qDiv_{{quiz.id}}">
                                    <h5 class="cs-text-gray">Question <label *ngIf="i+1<10">0{{i+1}}</label>
                                        <label *ngIf="i+1>=10">{{i+1}}</label>
                                    </h5>

                                    <div class="row cs-padding" id="Q_{{quiz.id}}">
                                        <div class="col-10">
                                            <h2>
                                                <label *ngIf="answersValidated && !levelPassed">
                                                    <i class="bx bx-check-circle cs-text-green mr10"
                                                        *ngIf="validatedAnswerList[i].state"></i>
                                                    <i class="bx bx-x-circle cs-text-red mr10"
                                                        *ngIf="!validatedAnswerList[i].state"></i>
                                                </label> {{quiz.question}} [{{quiz.answerSequence}}]
                                            </h2>
                                        </div>
                                    </div>

                                    <div class="row quiz-answers-div cs-padding cs-animate-bottom">
                                        <div class="col-lg-12 col-md-12 cs-padding-16">
                                            <div class="cursor_p row" [class.cs-hover-text-blue]="!levelPassed" (click)="selectAnswer(quiz.id,1)">
                                                <div class="col-1 mr10">
                                                    <button class="cs-button cs-circle cs-border  cs-small mr10 quiz-answers-button" id="QA_{{quiz.id}}_1">A</button>
                                                </div>
                                                <div class="col-10 pt-2" style="padding-left: 30px;">
                                                    {{quiz.answer01}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 cs-padding-16">
                                            <div class="cursor_p row" [class.cs-hover-text-blue]="!levelPassed" (click)="selectAnswer(quiz.id,2)">
                                                <div class="col-1 mr10">
                                                    <button class="cs-button cs-circle cs-border cs-small mr10 quiz-answers-button" id="QA_{{quiz.id}}_2">B</button>
                                                </div>
                                                <div class="col-10 pt-2" style="padding-left: 30px;">
                                                    {{quiz.answer02}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 cs-padding-16">
                                            <div class="cursor_p row" [class.cs-hover-text-blue]="!levelPassed" (click)="selectAnswer(quiz.id,3)">
                                                <div class="col-1 mr10">
                                                    <button class="cs-button cs-circle cs-border cs-small mr10 quiz-answers-button" id="QA_{{quiz.id}}_3">C</button>
                                                </div>
                                                <div class="col-10 pt-2" style="padding-left: 30px;">
                                                    {{quiz.answer03}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 cs-padding-16">
                                            <div class="cursor_p row" [class.cs-hover-text-blue]="!levelPassed" (click)="selectAnswer(quiz.id,4)">
                                                <div class="col-1 mr10">
                                                    <button class="cs-button cs-circle cs-border cs-small mr10 quiz-answers-button" id="QA_{{quiz.id}}_4">D</button>
                                                </div>
                                                <div class="col-10 pt-2" style="padding-left: 30px;">
                                                    {{quiz.answer04}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <button class="default-btn mb20 font_w_l cursor_p cs-right" style="border-radius: 50px;font-weight: lighter;" (click)="switchQuestion(quizData[i+1].id)">
                                        <i class='bx bx-chevron-right icon-arrow before'></i>
                                        <span class="label">Next Question</span>
                                        <i class="bx bx-chevron-right icon-arrow after"></i>
                                    </button>

                                </div>
                            </div>

                            <div style="align-content: center;" class="cs-padding-32 cs-hide" align='center' id="no_data">
                                <img src="../../../../../../assets/img/illus/void.svg" alt="" style="width: 300px;height: auto;">
                                <h3 style="padding-top: 50px;">Couldn't found any quizzes</h3>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="exam01Completed" class="cs-padding-16" align='center'>
                        <br>
                        <div id="examResultChart_div" style="width:300px;max-width:300px" class="cs-display-container">
                            <div class="cs-display-middle">
                                <h2>{{exam01resultDisplay}}</h2>
                            </div>
                        </div>
                        <br>
                        <h2>You Have Completed the Exam</h2>
                        <p>Continue the program and get Certified <i class="bx bx-certification"></i></p>
                        <a class="default-btn mb20 font_w_l cursor_p cs-right" style="border-radius: 50px;font-weight: lighter;" href="learn-film-marking-with-dbima/exam-project">
                            <i class='bx bx-chevron-right icon-arrow before'></i>
                            <span class="label">Exam 02</span>
                            <i class="bx bx-chevron-right icon-arrow after"></i>
                        </a>
                    </div>

                </div>

                <div class="cs-card cs-padding-16" align='center' style="margin-top: 100px;" [class.cs-hide]="!answersValidated">
                    <h3>Your Result</h3>
                    <div id="examResultChart_div" style="width:300px;max-width:300px" class="cs-display-container">
                        <div class="cs-display-middle" *ngIf="answersValidated">
                            <h2>{{correctAnswersCount*2}}/60</h2>
                        </div>
                    </div>
                    <br>
                    <div *ngIf="correctAnswersCount<20">
                        <h2>Unfortunately you didn't pass</h2>
                        <p>In order to pass the Exam you have to score at least 40/60.</p>
                        <p>Re-watch the lessons and try Again</p>
                        <a class="default-btn mb20 font_w_l cursor_p cs-right" style="border-radius: 50px;font-weight: lighter;" href="{{firstLessonURl}}">
                            <i class='bx bxs-caret-right-circle icon-arrow before'></i>
                            <span class="label">Watch the lesson</span>
                            <i class="bx bxs-caret-right-circle icon-arrow after"></i>
                        </a>

                    </div>
                    <div *ngIf="correctAnswersCount>=20">
                        <h2>Congratulations, you passed!</h2>
                        <p>Continue the program and get Certified <i class="bx bx-certification"></i></p>
                    </div>
                </div>

            </div>

            <div class="col-lg-1"></div>
        </div>
    </div>
</div>