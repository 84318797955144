import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { Router } from "@angular/router";
import { saveContact } from "src/app/models/endpoints";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
    selector: "app-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
    sendMessagePending = false;

    constructor(
        private router: Router,
        private http: HttpClient,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit(): void {}

    submit(form) {
        this.sendMessagePending = true;

        this.http
            .post<any>(environment.baseUrl + saveContact, {
                fullname: form.name,
                email: form.email,
                phone: form.number,
                subject: form.subject,
                message: form.message,
            })
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.openSnackBar("Message Sent", 1);
                        this.redirectTo("contact");
                    } else {
                        this.openSnackBar(data.message, 1);
                    }
                    this.sendMessagePending = false;
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                    this.sendMessagePending = false;
                },
            });
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, "Dismiss", { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, "Dismiss");
        }
    }

    redirectTo(uri: string) {
        this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() => this.router.navigate([uri]));
    }
}
