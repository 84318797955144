import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import {
    updateExamProjectURL,
    trackUserProgressState,
    projectURLSubmissionCheck,
} from "../../../../models/endpoints";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";

declare var loadExamResultChart;
@Component({
    selector: "app-exam-two",
    templateUrl: "./exam-two.component.html",
    styleUrls: ["./exam-two.component.scss"],
})
export class ExamTwoComponent implements OnInit {
    currentUserName: string;
    currentUserId: number;
    currentUserToken: string;
    currentUserProgressCourseID: number;
    currentUserProgressModuleID: number;
    currentUserProgressPartID: number;

    courseID = 6;

    nodata = true;

    all_quiz_completed = false;
    exam01Completed = false;
    exam02Completed = false;

    urlSubmitted = true;
    submittedURL;

    exam02resultDisplay;
    autoSaveInterval;

    constructor(
        private router: Router,
        private http: HttpClient,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit(): void {
        this.getCurrentUserData();
    }

    //------------------------------ User Data

    getCurrentUserData() {
        var userStatus = parseInt(localStorage.getItem("XJFYUserStatus"));
        var token = localStorage.getItem("XJFYToken");
        var userObj = JSON.parse(localStorage.getItem("XJFYUser"));

        if (userStatus == 1) {
            this.currentUserName = userObj.firstName + " " + userObj.lastName;
            this.currentUserId = userObj.id;
            this.currentUserToken = token;
            this.trackUserProgressState_function();
        } else {
            this.router.navigate(["error/401"]);
        }
    }

    trackUserProgressState_function() {
        this.http
            .get<any>(
                environment.baseUrl +
                    trackUserProgressState +
                    "userId=" +
                    this.currentUserId +
                    "&courseId=" +
                    this.courseID,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        localStorage.setItem(
                            "XJFYUserProgressCourseID",
                            data.courseId
                        );
                        localStorage.setItem(
                            "XJFYUserProgressModuleID",
                            data.tutorialId
                        );
                        localStorage.setItem(
                            "XJFYUserProgressPartID",
                            data.partId
                        );
                        this.currentUserProgressCourseID = data.courseId;
                        this.currentUserProgressModuleID = data.tutorialId;
                        this.currentUserProgressPartID = data.partId;

                        if (data.percentage == 100) {
                            this.all_quiz_completed = true;

                            if (!data.exam01) {
                                this.router.navigate(["error/401"]);
                            } else {
                                this.exam01Completed = true;
                                this.projectURLSubmissionCheck_fun();
                                if (!data.exam02) {
                                } else {
                                    this.exam02Completed = true;

                                    this.autoSaveInterval = setTimeout(() => {
                                        this.callToLoadExamResultChart(
                                            "exam02ResultChart",
                                            data.result02,
                                            40 - data.result02
                                        );
                                        this.exam02resultDisplay =
                                            "" + data.result02 + "/40";
                                    }, 1000);

                                }
                            }
                        } else {
                            this.router.navigate(["error/401"]);
                        }

                        if (data.percentage == 100) {
                        } else {
                            this.router.navigate(["error/401"]);
                        }
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    //---------------------------------------------------------------------------------------

    projectURLSubmissionCheck_fun() {
        this.http
            .get<any>(
                environment.baseUrl +
                    projectURLSubmissionCheck +
                    "userId=" +
                    this.currentUserId +
                    "&courseId=" +
                    this.courseID,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.urlSubmitted = true;
                        this.submittedURL = data.message;
                    } else {
                        this.urlSubmitted = false;
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    toggle_projectLinkSubmission() {
        document
            .getElementById("project_link_submission_btn")
            .classList.toggle("cs-hide");
        document
            .getElementById("project_link_submission_div")
            .classList.toggle("cs-hide");
    }

    callToLoadExamResultChart(id, correct, incorrect) {
        loadExamResultChart(id, correct, incorrect);
    }

    url_submit(form) {
        if (validURL(form.url)) {
            this.http
                .get<any>(
                    environment.baseUrl +
                        updateExamProjectURL +
                        "userId=" +
                        this.currentUserId +
                        "&courseId=" +
                        this.courseID +
                        "&url=" +
                        form.url,
                    {
                        headers: new HttpHeaders().set(
                            "Authorization",
                            this.currentUserToken
                        ),
                    }
                )
                .subscribe({
                    next: (data) => {
                        if (data.status == 1) {
                            this.urlSubmitted = true;
                            this.submittedURL = form.url;
                            this.openSnackBar("Project Link Submitted", 1);
                        }

                        console.log(data);
                    },
                    error: (error) => {
                        this.openSnackBar(
                            "There was an error! ," + error.message,
                            0
                        );
                    },
                });
        } else {
            this.openSnackBar("Invalid URL !", 1);
        }

        function validURL(str) {
            var pattern = new RegExp(
                "^(https?:\\/\\/)?" + // protocol
                    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
                    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                    "(\\#[-a-z\\d_]*)?$",
                "i"
            ); // fragment locator
            return !!pattern.test(str);
        }
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, "Dismiss", { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, "Dismiss");
        }
    }
}
