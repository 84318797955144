import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homesix-main-banner',
  templateUrl: './homesix-main-banner.component.html',
  styleUrls: ['./homesix-main-banner.component.scss']
})
export class HomesixMainBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
