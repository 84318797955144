<app-header></app-header>
<br><br><br><br>

<div class="courses-details-area pt-100 pb-70">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <div class="courses-title">
                        <div class="row">
                            <div class="col-10">
                                <h2 routerLink="/learn-film-marking-with-dbima" class="cursor_p">Film Marking With DBIMA
                                </h2>
                                <h4>Dashboard</h4>
                            </div>
                            <div class="col-2" style="text-align: right;">
                                <i class='bx bx-left-arrow-circle cursor_p cs-text-gray cs_nav_icon_2 cs-hover-text-blue' style="margin-top: 20px;" onclick="window.history.back();"></i>
                            </div>
                        </div>

                        <br>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">

            <div class="col-lg-4">
                <div class="courses-sidebar-information">
                    <h4>Program Overview</h4>
                    <ul>
                        <li class="cursor_p cs-hover-light-gray dash_link cs-light-gray" onclick="show_dashboard_dives('dash_modules',this)">
                            <span><i class='bx bx-box'></i> Modules:</span> 12
                        </li>
                        <li class="cursor_p cs-hover-light-gray dash_link" onclick="show_dashboard_dives('dash_lessons',this)">
                            <span><i class='bx bx-slideshow'></i> Lessons:</span> 36
                        </li>
                        <li class="cursor_p cs-hover-light-gray dash_link" onclick="show_dashboard_dives('dash_quizzes',this)">
                            <span><i class='bx bx-atom'></i> Quizzes:</span> 36
                        </li>
                        <li>
                            <div class="cursor_p">
                                <span><i class='bx bx-bolt-circle'></i> Exams:</span> 2
                            </div>
                            <br>
                            <div class="cs-animate-zoom" id="lessons">
                                <div>
                                    <button class="cs-button cs-block cs-round row  cs-hover-light-gray dash_link" onclick="show_dashboard_dives('dash_exam_1',this)" style="width: 90%;margin-left: 20px;">Exam 01
                                    </button>

                                    <button class="cs-button cs-block cs-round row  cs-hover-light-gray dash_link" onclick="show_dashboard_dives('dash_exam_2',this)" style="width: 90%;margin-left: 20px;">Exam 02
                                    </button>
                                </div>
                            </div>

                        </li>
                        <li class="cursor_p cs-hover-light-gray dash_link" onclick="show_dashboard_dives('dash_certificate',this)" id="certificate_dash_link_tab">
                            <span><i class='bx bx-certification'></i> Certificate:</span>
                        </li>

                    </ul>
                </div>
            </div>

            <div class="col-lg-1"></div>

            <div class="col-lg-6">
                <div class="courses-details-image cs-card">
                    <div style="padding: 30px;">


                        <div class="dashboard_dives cs-animate-zoom " id="dash_modules">

                            <div class="row">
                                <div class="col-lg-8 col-md-12">
                                    <div class="courses-details-header">
                                        <div class="courses-title">
                                            <h2>Modules</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                    <div class="container cs-display-container" style="height:100px;float: right;">
                                        <div id="modules_userProgressChart_div" style="width:200px;" class="cs-display-container">
                                            <div class="cs-display-middle">
                                                <h4>{{userProgressPercentage}}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="courses-details-desc">
                                <div class="row">
                                    <div class="col-12 mb20" *ngFor="let tutorial of tutorialData">
                                        <a href="learn-film-marking-with-dbima/module/{{tutorial.id}}" target="_blank" *ngIf="tutorial.unlocked">
                                            <i class='bx bx-box pr10' [class.cs-text-green]="tutorial.unlocked"></i> {{tutorial.tutorialName}}
                                            <p class="cs-small" style="margin-left: 20px;">
                                                {{tutorial.tutorialOverview}}
                                            </p>
                                        </a>
                                        <div *ngIf="!tutorial.unlocked" class=" cs-text-gray">
                                            <i class='bx bx-box pr10'></i> {{tutorial.tutorialName}}
                                            <p class="cs-small" style="margin-left: 20px;">
                                                {{tutorial.tutorialOverview}}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="dashboard_dives cs-animate-zoom cs-hide" id="dash_lessons">

                            <div class="row">
                                <div class="col-lg-8 col-md-12">
                                    <div class="courses-details-header">
                                        <div class="courses-title">
                                            <h2>Lessons</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                    <div class="container cs-display-container" style="height:100px;float: right;">
                                        <div id="lessons_userProgressChart_div" style="width:200px;" class="cs-display-container">
                                            <div class="cs-display-middle">
                                                <h4>{{userProgressPercentage}}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="courses-details-desc" style="max-height: 500px;overflow: auto;">

                                <div *ngFor="let tutorials of tutorialAllData ; let i = index" style="width: fit-content;">
                                    <label class="cs-tiny" style="width: fit-content;color: var(--paragraphColor);">{{i+1}}.
                                        {{tutorials.tutorial.tutorialName}} <i class='bx bx-lock' *ngIf="!tutorials.tutorial.unlocked"></i></label>
                                    <div style="margin-bottom: 20px;margin-top: 10px;" *ngFor="let lesson of tutorialAllData[i].parts;">

                                        <a href="learn-film-marking-with-dbima/module/{{tutorials.tutorial.id}}/lesson/{{lesson.id}}" target="_blank" class=" cs-hover-text-blue" style="padding-left: 30px;" *ngIf="lesson.unlocked"><i
                                                class='bx bx-play-circle' [class.cs-text-green]="lesson.unlocked"></i> {{lesson.partName}}<span
                                                class="cs-tiny ml10">{{lesson.partDurationMinute}}min
                                                {{lesson.partDurationSecond}}sec</span></a>

                                        <div class="cs-text-gray" style="padding-left: 30px;" *ngIf="!lesson.unlocked"><i class='bx bx-lock'></i> {{lesson.partName}}</div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="dashboard_dives cs-animate-zoom cs-hide" id="dash_quizzes">

                            <div class="row">
                                <div class="col-lg-8 col-md-12">
                                    <div class="courses-details-header">
                                        <div class="courses-title">
                                            <h2>Quizzes</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                    <div class="container cs-display-container" style="height:100px;float: right;">
                                        <div id="quizzes_userProgressChart_div" style="width:200px;" class="cs-display-container">
                                            <div class="cs-display-middle">
                                                <h4>{{userProgressPercentage}}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="courses-details-desc" style="max-height: 500px;overflow: auto;">

                                <div *ngFor="let tutorials of tutorialAllData ; let i = index" style="width: fit-content;">
                                    <label class="cs-tiny" style="width: fit-content;color: var(--paragraphColor);">{{i+1}}.
                                        {{tutorials.tutorial.tutorialName}} <i class='bx bx-lock' *ngIf="!tutorials.tutorial.unlocked"></i></label>
                                    <div style="margin-bottom: 20px;margin-top: 10px;" *ngFor="let lesson of tutorialAllData[i].parts;">

                                        <a href="learn-film-marking-with-dbima/module/{{tutorials.tutorial.id}}/lesson/{{lesson.id}}/quiz" target="_blank" class=" cs-hover-text-blue" style="padding-left: 30px;" *ngIf="lesson.unlocked"><i
                                                class='bx bx-atom' [class.cs-text-green]="lesson.unlocked"></i> {{lesson.partName}}</a>

                                        <div class="cs-text-gray" style="padding-left: 30px;" *ngIf="!lesson.unlocked"><i class='bx bx-lock'></i> {{lesson.partName}}</div>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div class="dashboard_dives cs-animate-zoom cs-hide" id="dash_exam_1">

                            <a *ngIf="exam01Completed" class="default-btn mb20 font_w_l cursor_p cs-padding" style=" float: right;border-radius: 50px 10px 10px 50px;" href="learn-film-marking-with-dbima/exam-mcq" target="_blank">
                                <i class='bx bx-paper-plane cs-large'></i>
                            </a>

                            <div class="courses-details-header">
                                <div class="courses-title">
                                    <h2>Exam 01</h2>
                                </div>
                            </div>
                            <div class="courses-details-desc">

                                <div class="cs-padding-16" align='center' *ngIf="!all_quiz_completed">
                                    <br>
                                    <div class="cs-display-container">
                                        <div class="cs-display-middle">
                                            <i class='bx bx-lock cs-xxxlarge' style="color: var(--mainColor);"></i>
                                        </div>
                                    </div>
                                    <br>
                                    <div>
                                        <h2>Locked</h2>
                                        <p>You Have to Complete all the tutorial Quizzes <br> to get access to the Exam 01</p>
                                    </div>
                                </div>

                                <div class="cs-padding-16" align='center' *ngIf="all_quiz_completed && !exam01Completed">
                                    <br>
                                    <div class="cs-display-container">
                                        <div class="cs-display-middle">
                                            <i class='bx bx-lock-open cs-xxxlarge' style="color: var(--mainColor);"></i>
                                        </div>
                                    </div>
                                    <br>
                                    <div>
                                        <h2>Unlocked</h2>
                                        <p>You Have to unlocked the Exam 01 <br> go for it, Best of luck! </p>
                                        <br>
                                        <a href="learn-film-marking-with-dbima/exam-mcq" class="default-btn" target="_blank">
                                            <i class='bx bx-log-in-circle icon-arrow before'></i>
                                            <span class="label">Attend Exam 01</span>
                                            <i class="bx bx-log-in-circle icon-arrow after"></i>
                                        </a>
                                    </div>
                                </div>

                                <div *ngIf="exam01Completed" class="cs-padding-16" align='center'>
                                    <br>
                                    <div id="exam01ResultChart_div" style="width:300px;max-width:300px" class="cs-display-container">
                                        <div class="cs-display-middle">
                                            <h2>{{exam01resultDisplay}}</h2>
                                        </div>
                                    </div>
                                    <br>
                                    <div *ngIf="!allCompleted">
                                        <h2>You Have Completed the Exam</h2>
                                        <p>Continue the program and get Certified <i class="bx bx-certification"></i>
                                        </p>
                                        <br>
                                        <a href="learn-film-marking-with-dbima/exam-project" class="default-btn" target="_blank">
                                            <i class='bx bx-log-in-circle icon-arrow before'></i>
                                            <span class="label">Continue to Exam 02</span>
                                            <i class="bx bx-log-in-circle icon-arrow after"></i>
                                        </a>
                                    </div>
                                    <div *ngIf="allCompleted">
                                        <h2>You Have Completed the Program</h2>
                                        <p class="cursor_p cs-hover-text-blue" onclick="document.getElementById('certificate_dash_link_tab').click();">Get your Certificate Now ! <i class="bx bx-certification"></i></p>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div class="dashboard_dives cs-animate-zoom cs-hide" id="dash_exam_2">
                            <a *ngIf="exam02Completed" class="default-btn mb20 font_w_l cursor_p cs-padding" style=" float: right;border-radius: 50px 10px 10px 50px;" href="learn-film-marking-with-dbima/exam-project" target="_blank">
                                <i class='bx bx-paper-plane cs-large'></i>
                            </a>

                            <div class="courses-details-header">
                                <div class="courses-title">
                                    <h2>Exam 02</h2>
                                </div>
                            </div>
                            <div class="courses-details-desc">
                                <div class="cs-padding-16" align='center' *ngIf="!exam01Completed">
                                    <br>
                                    <div class="cs-display-container">
                                        <div class="cs-display-middle">
                                            <i class='bx bx-lock cs-xxxlarge' style="color: var(--mainColor);"></i>
                                        </div>
                                    </div>
                                    <br>
                                    <div>
                                        <h2>Locked</h2>
                                        <p>You Have to Complete Exam 01 <br> to get access to the Exam 02</p>
                                    </div>
                                </div>
                                <div class="cs-padding-16" align='center' *ngIf="exam01Completed && !urlSubmitted">
                                    <br>
                                    <div class="cs-display-container">
                                        <div class="cs-display-middle">
                                            <i class='bx bx-lock-open cs-xxxlarge' style="color: var(--mainColor);"></i>
                                        </div>
                                    </div>
                                    <br>
                                    <div>
                                        <h2>Unlocked {{urlSubmitted}}</h2>
                                        <p>You Have to unlocked the Exam 02 <br> Follow the Instruction , Best of luck! </p>
                                        <br>
                                        <a href="learn-film-marking-with-dbima/exam-project" class="default-btn" target="_blank">
                                            <i class='bx bx-log-in-circle icon-arrow before'></i>
                                            <span class="label">Exam 02 Instruction</span>
                                            <i class="bx bx-log-in-circle icon-arrow after"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="cs-padding-16" align='center' *ngIf="exam01Completed && urlSubmitted && !exam02Completed">
                                    <br>
                                    <div class="cs-display-container">
                                        <div class="cs-display-middle">
                                            <i class='bx bx-loader-alt cs-xxxlarge' style="color: var(--mainColor);"></i>
                                        </div>
                                    </div>
                                    <br>
                                    <div>
                                        <h2>Result Pending</h2>
                                        <p>You Have to Submitted your Project URL<br> Your Results Will be Released Soon! </p>
                                        <p><b>Your Project URL</b> <br>{{submittedURL}}</p>
                                        <br>
                                    </div>
                                </div>
                                <div *ngIf="exam02Completed" class="cs-padding-16" align='center'>
                                    <br>
                                    <div id="exam02ResultChart_div" style="width:300px;max-width:300px" class="cs-display-container">
                                        <div class="cs-display-middle">
                                            <h2>{{exam02resultDisplay}}</h2>
                                        </div>
                                    </div>
                                    <br>
                                    <div *ngIf="!allCompleted">
                                        <h2>You Have Completed the Exam</h2>
                                        <p>Continue the program and get Certified <i class="bx bx-certification"></i>
                                        </p>
                                    </div>
                                    <div *ngIf="allCompleted">
                                        <h2>You Have Completed the Program</h2>
                                        <p class="cursor_p cs-hover-text-blue" onclick="document.getElementById('certificate_dash_link_tab').click();">Get your Certificate Now ! <i class="bx bx-certification"></i></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="dashboard_dives cs-animate-zoom cs-hide" id="dash_certificate">
                            <div class="courses-details-header">
                                <div class="courses-title">
                                    <h2>Certificate</h2>
                                </div>
                            </div>

                            <div class="courses-details-desc">
                                <div class="cs-padding-16" align='center' *ngIf="!allCompleted">
                                    <br>
                                    <div class="cs-display-container">
                                        <div class="cs-display-middle">
                                            <i class='bx bx-lock cs-xxxlarge' style="color: var(--mainColor);"></i>
                                        </div>
                                    </div>
                                    <br>
                                    <div>
                                        <h2>Locked</h2>
                                        <p>You Have to All Quizzes and Exam <br> to get access to the Certificate</p>
                                    </div>
                                </div>
                                <div *ngIf="allCompleted" class="cs-padding-16" align='center'>
                                    <br>
                                    <div id="fullResultChart_div" style="width:300px;max-width:300px" class="cs-display-container">
                                        <div class="cs-display-middle">
                                            <h2>{{fullResultDisplay}}</h2>
                                        </div>
                                    </div>
                                    <br>
                                    <h2>You Have Completed the Program</h2>
                                    <button *ngIf="!profileDataMissing && !downloadPending" class="default-btn mb20 font_w_l cursor_p cs-blue cs-border cs-border-blue" style="border-radius: 20px 20px 0px 0px;" (click)="getYourCertificate()">
                                        <i class='bx bx-certification icon-arrow before'></i>
                                        <span class="label">Get Your Certificate</span>
                                        <i class="bx bx-cloud-download icon-arrow after"></i>
                                    </button>

                                    <button *ngIf="!profileDataMissing && downloadPending" class="default-btn mb20 font_w_l cursor_p cs-blue cs-border cs-border-blue" style="border-radius: 20px 20px 0px 0px;" (click)="getYourCertificate()">
                                        <i class='icon-arrow before'><i class="bx bx-loader-alt fa-spin "></i></i>
                                        <span class="label">Sending request</span>
                                        <i class="icon-arrow after"><i class="bx bx-loader-alt fa-spin "></i></i>
                                    </button>


                                    <div align='center' *ngIf="profileDataMissing">
                                        <hr>
                                        <div>
                                            <i class='bx bx-lock cs-xxxlarge' style="color: var(--mainColor);"></i>
                                            <h2>Certificate Locked</h2>
                                            <p>You Are missing Some Data in Your Profile <br> that is Required for generate Certificate</p>
                                            <p>Update Missing Data in your Profile</p>
                                        </div>
                                        <br>
                                        <a class="default-btn mb20 font_w_l cursor_p" href="/my-dashboard" style="border-radius: 20px 20px 0px 0px;">
                                            <i class='bx bx-paper-plane icon-arrow before'></i>
                                            <span class="label">Update Profile</span>
                                            <i class="bx bx-paper-plane icon-arrow after"></i>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-lg-1"></div>
        </div>
    </div>
</div>