import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { newsletterSubscribe } from "../../../models/endpoints";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
    subscribePending = false;

    constructor(
        private router: Router,
        private http: HttpClient,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit(): void {}

    newsletterSubscribe_fun(form) {
        this.subscribePending = true;
        this.http
            .get<any>(environment.baseUrl + newsletterSubscribe + form.email)
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.openSnackBar(data.message, 1);
                    } else {
                        this.openSnackBar("You have Already Subscribed ...", 1);
                    }

                    this.subscribePending = false;
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                    this.subscribePending = false;
                },
            });
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, "Dismiss", { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, "Dismiss");
        }
    }
}
