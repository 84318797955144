import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hometwo-about',
  templateUrl: './hometwo-about.component.html',
  styleUrls: ['./hometwo-about.component.scss']
})
export class HometwoAboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
