<i class='bx bx-grid-alt cs-xlarge cursor_p cs-text-gray nav_icon' style="margin-top: 20px;" (click)="toggleClass3()"></i>

<div class="search-overlay" [class.active]="classApplied3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-close" (click)="toggleClass3()">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>
            <div class="search-overlay-form">
                <div class="courses-sidebar-information" style="max-height: 600px;overflow: auto;background-color: transparent;">
                    <ul>
                        <li *ngFor="let tutorialsData of tutorialAllData ; let i = index" class="cursor_p  cs-hover-light-gray" onclick="toggleNavigationTab(this)" style="background-color: transparent;">
                            <div class="cursor_p row tab_name" *ngIf="tutorialsData.tutorial.unlocked">
                                <div class="col-1">
                                    <i class='bx bx-box cs-medium'></i>
                                </div>
                                <div class="col-10 cs-small" style="text-align: left;">
                                    {{tutorialsData.tutorial.tutorialName}}
                                </div>
                                <div class="col-1" style="margin: 0px;padding: 0px;text-align: start;">
                                    <i class="bx bx-chevron-down cs-medium toggleIcon"></i>
                                </div>
                            </div>

                            <div class="cursor_p row tab_name cs-text-gray" *ngIf="!tutorialsData.tutorial.unlocked">
                                <div class="col-1">
                                    <i class='bx bx-box cs-medium'></i>
                                </div>
                                <div class="col-10 cs-small" style="text-align: left;">
                                    {{tutorialsData.tutorial.tutorialName}}
                                </div>
                                <div class="col-1" style="margin: 0px;padding: 0px;text-align: start;">
                                    <i class="bx bx-lock cs-medium toggleIcon"></i>
                                </div>
                            </div>

                            <div class="cs-animate-bottom cs-hide module_routes_card" style="padding: 20px 10px 10px 10px;" id="m_{{tutorialsData.tutorial.id}}" *ngIf="tutorialsData.tutorial.unlocked">
                                <div *ngFor="let part of tutorialsData.parts ; let i = index" class="cursor_p">

                                    <a class="row cursor_p" style="margin-left: 5px;" *ngIf="part.unlocked" href='/learn-film-marking-with-dbima/module/{{tutorialsData.tutorial.id}}/lesson/{{part.id}}'>
                                        <div class="col-11 cs-left-align cursor_p  cs-padding cs-round" [class.cs-hover-pale-yellow]="currentUserProgressPartID == part.id && !all_quiz_completed" [class.cs-hover-pale-green]="currentUserProgressPartID != part.id || all_quiz_completed">
                                            <div class="row cursor_p">
                                                <div class="col-1">
                                                    <i class='bx bx-book cs-medium'></i>
                                                </div>
                                                <div class="col-9 cs-small" style="text-align: left;">
                                                    {{part.partName}}
                                                </div>
                                                <div class="col-2" style="margin: 0px;padding: 0px;text-align: center;">
                                                    <i class="bx bx-loader-alt cs-medium cs-text-blue" *ngIf="currentUserProgressPartID == part.id && !all_quiz_completed"></i>
                                                    <i class="bx bxs-check-circle cs-medium cs-text-green" *ngIf="currentUserProgressPartID != part.id || all_quiz_completed"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </a>

                                    <a class="row cs-text-gray" style="margin-left: 5px;cursor: default;" *ngIf="!part.unlocked">
                                        <div class="col-11 cs-left-align  cs-padding cs-round">
                                            <div class="row">
                                                <div class="col-1">
                                                    <i class='bx bx-book cs-medium'></i>
                                                </div>
                                                <div class="col-9 cs-small" style="text-align: left;">
                                                    {{part.partName}}
                                                </div>
                                                <div class="col-2" style="margin: 0px;padding: 0px;text-align: center;">
                                                    <i class="bx bx-lock cs-medium"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </a>

                                </div>
                            </div>

                            <div class="cs-animate-bottom cs-hide module_routes_card cs-text-gray" style="padding: 10px;cursor: default;" id="m_{{tutorialsData.tutorial.id}}" *ngIf="!tutorialsData.tutorial.unlocked">
                                <div *ngFor="let part of tutorialsData.parts ; let i = index">
                                    <a class="row cs-text-gray" style="margin-left: 5px;cursor: default;">
                                        <div class="col-11 cs-left-align  cs-padding cs-round">
                                            <div class="row">
                                                <div class="col-1">
                                                    <i class='bx bx-book cs-medium'></i>
                                                </div>
                                                <div class="col-9 cs-small" style="text-align: left;">
                                                    {{part.partName}}
                                                </div>
                                                <div class="col-2" style="margin: 0px;padding: 0px;text-align: center;">
                                                    <i class="bx bx-lock cs-medium"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>


                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
