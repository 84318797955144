import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-dashboard-page',
  templateUrl: './my-dashboard-page.component.html',
  styleUrls: ['./my-dashboard-page.component.scss']
})
export class MyDashboardPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
