import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import {
    getAllModuleData,
    trackUserProgressState,
    getTNPIdList,
} from "../../../../models/endpoints";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router, ActivatedRoute } from "@angular/router";
import { CourseClass } from "src/app/classes/CourseClass";
import { TutorialClass } from "src/app/classes/TutorialClass";
import { PartClass } from "./../../../../classes/PartClass";

@Component({
    selector: "app-lesson",
    templateUrl: "./lesson.component.html",
    styleUrls: ["./lesson.component.scss"],
})
export class LessonComponent implements OnInit {
    myInterval: any;

    url_data;

    courseID = 6;
    language;
    moduleID;
    lessonID;

    courseData = new CourseClass();
    tutorialData = new TutorialClass();
    tutorialAllData;
    partData;
    partCount;

    lessonPartData = new PartClass();

    youTubeURL;

    currentUserName: string;
    currentUserId: number;
    currentUserToken: string;
    currentUserProgressCourseID: number;
    currentUserProgressModuleID: number;
    currentUserProgressPartID: number;

    thisLastUnlockedLesson;
    thisPageIsUnlocked = false;

    tnpData;

    synth = window.speechSynthesis;
    voices = [];


    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private http: HttpClient,
        private snackBar: MatSnackBar
    ) {
        this.activatedRoute.params.subscribe(
            (params) => (this.url_data = params)
        );
    }

    ngOnInit(): void {
        this.moduleID = this.url_data.l_module_id;
        this.lessonID = this.url_data.l_lesson_id;
        this.getCurrentUserData();

        this.myInterval = setInterval(() => {
            if (!this.synth.speaking) {
                this.toggle_view("speak_btn", 1);
                this.toggle_view("pause_btn", 0);
                this.toggle_view("stop_btn", 0);
            }
        }, 500);
    }

    //------------------------------ User Data

    getCurrentUserData() {
        var userStatus = parseInt(localStorage.getItem("XJFYUserStatus"));
        var token = localStorage.getItem("XJFYToken");
        var userObj = JSON.parse(localStorage.getItem("XJFYUser"));

        if (userStatus == 1) {
            this.currentUserName = userObj.firstName + " " + userObj.lastName;
            this.currentUserId = userObj.id;
            this.currentUserToken = token;
            this.trackUserProgressState_function();
        }
    }

    trackUserProgressState_function() {
        this.http
            .get<any>(
                environment.baseUrl +
                    trackUserProgressState +
                    "userId=" +
                    this.currentUserId +
                    "&courseId=" +
                    this.courseID,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        localStorage.setItem(
                            "XJFYUserProgressCourseID",
                            data.courseId
                        );
                        localStorage.setItem(
                            "XJFYUserProgressModuleID",
                            data.tutorialId
                        );
                        localStorage.setItem(
                            "XJFYUserProgressPartID",
                            data.partId
                        );
                        this.currentUserProgressCourseID = data.courseId;
                        this.currentUserProgressModuleID = data.tutorialId;
                        this.currentUserProgressPartID = data.partId;
                        this.accessCheckForThisPage();
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }
    //---------------------------------------------------------------------------------------

    //------------------------------ Access Validators

    accessCheckForThisPage() {
        this.http
            .get<any>(environment.baseUrl + getTNPIdList, {
                headers: new HttpHeaders().set(
                    "Authorization",
                    localStorage.getItem("XJFYToken")
                ),
            })
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.tnpData = data.idList;
                        this.validateUserAccess();
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    validateUserAccess() {
        var tnpDataArray = this.tnpData;
        var LastUnlockedModule = parseInt(
            localStorage.getItem("XJFYUserProgressModuleID")
        );

        var LastUnlockedLesson = parseInt(
            localStorage.getItem("XJFYUserProgressPartID")
        );

        this.thisLastUnlockedLesson = LastUnlockedLesson;
        var indexOfLastUnlockedTNP;

        for (let index = 0; index < tnpDataArray.length; index++) {
            tnpDataArray[index];
            if (
                tnpDataArray[index].tutorialId == LastUnlockedModule &&
                tnpDataArray[index].partId == LastUnlockedLesson
            ) {
                indexOfLastUnlockedTNP = index;
            }
        }

        for (let index = 0; index <= indexOfLastUnlockedTNP; index++) {
            tnpDataArray[index].unlocked = true;
            if (
                tnpDataArray[index].tutorialId == this.moduleID &&
                tnpDataArray[index].partId == this.lessonID
            ) {
                this.thisPageIsUnlocked = true;
            }
        }

        if (!this.thisPageIsUnlocked) {
            this.router.navigate(["error/401"]);
        } else {
            this.init_textToSpeak();
            this.loadData();
        }
    }

    //---------------------------------------------------------------------------------------

    //------------------------------ Text to Speak

    init_textToSpeak() {
        var voiceList = document.getElementById(
            "voiceList"
        ) as HTMLSelectElement;

        this.voices = this.synth.getVoices();
        var selectedIndex =
            voiceList.selectedIndex < 0 ? 0 : voiceList.selectedIndex;
        voiceList.innerHTML = "";
        this.voices.forEach((voice) => {
            var listItem = document.createElement("option");
            listItem.textContent = voice.name;
            listItem.setAttribute("data-lang", voice.lang);
            listItem.setAttribute("data-name", voice.name);
            voiceList.appendChild(listItem);
        });

        voiceList.selectedIndex = selectedIndex;
    }

    speak_textToSpeak(text_id) {
        var voiceList = document.getElementById(
            "voiceList"
        ) as HTMLSelectElement;
        var text = document.getElementById(text_id).innerText;
        var toSpeak = new SpeechSynthesisUtterance(text);
        var selectedVoiceName =
            voiceList.selectedOptions[0].getAttribute("data-name");
        this.voices.forEach((voice) => {
            if (voice.name === selectedVoiceName) {
                toSpeak.voice = voice;
            }
        });
        this.synth.speak(toSpeak);

        if (this.synth.speaking) {
            this.toggle_view("speak_btn", 0);
            this.toggle_view("pause_btn", 1);
            this.toggle_view("stop_btn", 1);
        }
    }

    pause_textToSpeak() {
        this.synth.pause();
        this.toggle_view("pause_btn", 0);
        this.toggle_view("resume_btn", 1);
    }

    resume_textToSpeak() {
        this.synth.resume();
        this.toggle_view("pause_btn", 1);
        this.toggle_view("resume_btn", 0);
    }

    stop_textToSpeak() {
        this.synth.cancel();
        this.toggle_view("speak_btn", 1);
        this.toggle_view("pause_btn", 0);
        this.toggle_view("resume_btn", 0);
        this.toggle_view("stop_btn", 0);
    }

    toggle_settings_textToSpeak() {
        document
            .getElementById("settings_textToSpeak_div")
            .classList.toggle("cs-hide");
    }

    toggle_view(id, state) {
        if (state == 1) {
            document.getElementById(id).classList.remove("cs-hide");
        } else if (state == 0) {
            document.getElementById(id).classList.add("cs-hide");
        }
    }

    //---------------------------------------------------------------------------------------

    loadData() {
        this.language = "E";
        this.http
            .get<any>(
                environment.baseUrl +
                    getAllModuleData +
                    "language=" +
                    this.language +
                    "&courseId=" +
                    this.courseID,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        localStorage.getItem("XJFYToken")
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    this.courseData = data.course;
                    this.tutorialAllData = data.tutorialDtos;

                    for (
                        let index = 0;
                        index < this.tutorialAllData.length;
                        index++
                    ) {
                        if (
                            this.tutorialAllData[index].tutorial.id ==
                            this.moduleID
                        ) {
                            this.partData = this.tutorialAllData[index].parts;
                            this.tutorialData =
                                this.tutorialAllData[index].tutorial;
                            for (
                                let index2 = 0;
                                index2 < this.partData.length;
                                index2++
                            ) {
                                if (this.partData[index2].id == this.lessonID) {
                                    this.lessonPartData = this.partData[index2];
                                }
                            }
                        }
                    }

                    this.partCount = this.partData.length;

                    this.youTubeURL = this.formatYouTubeLink();

                    setTimeout(() => {
                        (<HTMLIFrameElement>(
                            document.getElementById("youTubeTutorialIframe")
                        )).src = this.youTubeURL;
                    }, 200);
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    formatYouTubeLink() {
        var dbURL_string = new String(this.lessonPartData.partUrl);
        var start = dbURL_string.indexOf("?v=");
        start = start + 3;
        var end = dbURL_string.indexOf("&");
        if (end == -1) {
            var videoId = dbURL_string.substring(start);
        } else {
            var videoId = dbURL_string.substring(start, end);
        }
        var reCreateLink = "https://www.youtube.lk/embed/" + videoId + "?rel=0";

        return reCreateLink;

        /*var dbURL_string = new String(dbURL);
        var start = dbURL_string.lastIndexOf("/");
        var videoId = dbURL_string.substring(start + 1);
        var reCreateLink = "https://www.youtube.lk/embed/" + videoId + "?rel=0";
        return reCreateLink;*/
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, "Dismiss", { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, "Dismiss");
        }
    }

    toggle_transcript() {
        var transcript_toggle_btn = document.getElementById(
            "transcript_toggle_btn"
        );
        if (transcript_toggle_btn.innerText == "VIEW TRANSCRIPT") {
            transcript_toggle_btn.innerText = "HIDE TRANSCRIPT";
        } else {
            transcript_toggle_btn.innerText = "VIEW TRANSCRIPT";
        }

        document.getElementById("transcript").classList.toggle("cs-hide");
    }

}
