<div class="row">
    <div class="col-lg-3 col-md-3 col-6">
        <div class="single-funfact">
            <div class="icon">
                <i class='bx bxs-group'></i>
            </div>
            <h3><span [countUp]="50">00</span></h3>
            <p>Expert Instructors</p>
        </div>
    </div>
    <div class="col-lg-3 col-md-3 col-6">
        <div class="single-funfact">
            <div class="icon">
                <i class='bx bx-book-reader'></i>
            </div>
            <h3><span [countUp]="1754">00</span></h3>
            <p>Total Courses</p>
        </div>
    </div>
    <div class="col-lg-3 col-md-3 col-6">
        <div class="single-funfact">
            <div class="icon">
                <i class='bx bx-user-pin'></i>
            </div>
            <h3><span [countUp]="8190">00</span></h3>
            <p>Happy Students</p>
        </div>
    </div>
    <div class="col-lg-3 col-md-3 col-6">
        <div class="single-funfact">
            <div class="icon">
                <i class='bx bxl-deviantart'></i>
            </div>
            <h3><span [countUp]="654">00</span></h3>
            <p>Creative Events</p>
        </div>
    </div>
</div>