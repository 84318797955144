import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page-four',
  templateUrl: './home-page-four.component.html',
  styleUrls: ['./home-page-four.component.scss']
})
export class HomePageFourComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
