import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import {
    getAllModuleData,
    trackUserProgressState,
} from "../../../models/endpoints";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
    selector: "app-tutorial-navigation",
    templateUrl: "./tutorial-navigation.component.html",
    styleUrls: ["./tutorial-navigation.component.scss"],
})
export class TutorialNavigationComponent implements OnInit {
    courseID;
    language;

    tutorialAllData;

    currentUserName: string;
    currentUserId: number;
    currentUserToken: string;
    currentUserProgressCourseID: number;
    currentUserProgressModuleID: number;
    currentUserProgressPartID: number;

    all_quiz_completed = false;

    constructor(private http: HttpClient, private snackBar: MatSnackBar) {}

    ngOnInit(): void {
        this.language = "E";
        this.courseID = 6;
        this.getCurrentUserData();
    }

    //------------------------------ User Data

    getCurrentUserData() {
        var userStatus = parseInt(localStorage.getItem("XJFYUserStatus"));
        var token = localStorage.getItem("XJFYToken");
        var userObj = JSON.parse(localStorage.getItem("XJFYUser"));

        if (userStatus == 1) {
            this.currentUserName = userObj.firstName + " " + userObj.lastName;
            this.currentUserId = userObj.id;
            this.currentUserToken = token;
            this.trackUserProgressState_function();
        }
    }

    trackUserProgressState_function() {
        this.http
            .get<any>(
                environment.baseUrl +
                    trackUserProgressState +
                    "userId=" +
                    this.currentUserId +
                    "&courseId=" +
                    this.courseID,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        localStorage.setItem(
                            "XJFYUserProgressCourseID",
                            data.courseId
                        );
                        localStorage.setItem(
                            "XJFYUserProgressModuleID",
                            data.tutorialId
                        );
                        localStorage.setItem(
                            "XJFYUserProgressPartID",
                            data.partId
                        );
                        this.currentUserProgressCourseID = data.courseId;
                        this.currentUserProgressModuleID = data.tutorialId;
                        this.currentUserProgressPartID = data.partId;
                        if (data.percentage == 100) {
                            this.all_quiz_completed = true;
                        }
                        this.loadData();
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }
    //---------------------------------------------------------------------------------------

    loadData() {
        this.http
            .get<any>(
                environment.baseUrl +
                    getAllModuleData +
                    "language=" +
                    this.language +
                    "&courseId=" +
                    this.courseID,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    this.tutorialAllData = data.tutorialDtos;
                    var tnpDataArray = new Array();
                    var tnpDataIndex = 0;

                    for (
                        let index = 0;
                        index < this.tutorialAllData.length;
                        index++
                    ) {
                        var pDataArray = this.tutorialAllData[index].parts;
                        this.tutorialAllData[index].tutorial.unlocked = false;

                        for (
                            let index2 = 0;
                            index2 < pDataArray.length;
                            index2++
                        ) {
                            tnpDataArray[tnpDataIndex] = {
                                t_id: this.tutorialAllData[index].tutorial.id,
                                p_id: pDataArray[index2].id,
                                unlocked: false,
                            };

                            this.tutorialAllData[index].parts[index2].unlocked =
                                false;

                            tnpDataIndex++;
                        }
                    }

                    var indexOfLastUnlockedTNP;

                    for (let index = 0; index < tnpDataArray.length; index++) {
                        tnpDataArray[index];
                        if (
                            tnpDataArray[index].t_id == this.currentUserProgressModuleID &&
                            tnpDataArray[index].p_id == this.currentUserProgressPartID
                        ) {
                            indexOfLastUnlockedTNP = index;
                        }
                    }

                    for (
                        let index = 0;
                        index <= indexOfLastUnlockedTNP;
                        index++
                    ) {
                        tnpDataArray[index].unlocked = true;

                        for (
                            let index2 = 0;
                            index2 < this.tutorialAllData.length;
                            index2++
                        ) {
                            var this_tutorialData =
                                this.tutorialAllData[index2].tutorial;
                            var this_partData =
                                this.tutorialAllData[index2].parts;
                            for (
                                let index3 = 0;
                                index3 < this_partData.length;
                                index3++
                            ) {
                                if (
                                    tnpDataArray[index].t_id ==
                                        this_tutorialData.id &&
                                    tnpDataArray[index].p_id ==
                                        this_partData[index3].id
                                ) {
                                    this_partData[index3].unlocked = true;
                                    this_tutorialData.unlocked = true;
                                }
                            }
                        }
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    classApplied3 = false;
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, "Dismiss", { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, "Dismiss");
        }
    }
}
