<app-header></app-header>
<br><br><br><br>

<div class="error-404-area">
    <div class="container">
        <div class="notfound">
            <div class="notfound-bg">
                <div></div>
                <div></div>
                <div></div>
            </div>
            <h1>Oops!</h1>
            <h3>{{error_header}}</h3>
            <p>{{error_body}}</p>
            <a routerLink="/" class="default-btn"><i class='bx bx-home-circle icon-arrow before'></i><span class="label">Back Home</span><i class="bx bx-home-circle icon-arrow after"></i></a>
        </div>
    </div>
</div>