<div class="banner-wrapper">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="wrapper-content">
                    <h1>A better future starts here</h1>
                    <p>Ednuv supports students by introducing collaborators outside R, internships and research experience abroad.</p>
                    <form>
                        <input type="text" class="input-search" placeholder="What do you want to learn?">
                        <button type="button">Search</button>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="banner-courses-category">
                    <ul>
                        <li>
                            <a routerLink="/">
                                <i class='bx bx-shape-triangle'></i>
                                Technology
                            </a>
                        </li>
                        <li>
                            <a routerLink="/">
                                <i class='bx bx-font-family'></i>
                                Language
                            </a>
                        </li>
                        <li>
                            <a routerLink="/">
                                <i class='bx bxs-drink'></i>
                                Science
                            </a>
                        </li>
                        <li>
                            <a routerLink="/">
                                <i class='bx bx-first-aid'></i>
                                Health
                            </a>
                        </li>
                        <li>
                            <a routerLink="/">
                                <i class='bx bx-bar-chart-alt-2'></i>
                                Humanities
                            </a>
                        </li>
                        <li>
                            <a routerLink="/">
                                <i class='bx bx-briefcase-alt-2'></i>
                                Business
                            </a>
                        </li>
                        <li>
                            <a routerLink="/">
                                <i class='bx bx-book-reader'></i>
                                Math
                            </a>
                        </li>
                        <li>
                            <a routerLink="/">
                                <i class='bx bx-target-lock'></i>
                                Marketing
                            </a>
                        </li>
                        <li>
                            <a routerLink="/">
                                <i class='bx bx-desktop'></i>
                                Design
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>