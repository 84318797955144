export class PartClass {
     id: number;
     courseId: number;
     tutorialId: number;
     partName: string;
     partUrl: string;
     partTranscript: string;
     languageCode: string;
     partDurationMinute: number;
     partDurationSecond: number;
     quizAvailable: boolean;
}
