<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Contact Us</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="#" target="_blank">
                                Cagliero Creative Solutions
                                <br>
                                Cagliero E-Cademy for Erasmus
                                <br>
                                75, Rue Alexandre Dumas,
                                Paris 75020, France
                            </a>
                        </li>
                        <!--
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:+11234567890">+1 (123) 456 7890</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:hello@ednuv.com">hello@ednuv.com</a>
                        </li>-->
                    </ul>
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Support</h3>
                    <ul class="support-link">
                        <li><a routerLink="/">Privacy Policy</a></li>
                        <li><a routerLink="/">Cookie Policy</a></li>
                        <li><a routerLink="/">Terms and Conditions</a></li>
                        <li><a routerLink="/">FAQ’s</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Useful Link</h3>
                    <ul class="useful-link">
                        <li><a routerLink="/register">Register</a></li>
                        <li><a routerLink="/login">Login</a></li>
                        <li><a routerLink="/certification-verify">Verify Certificate</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Newsletter</h3>
                    <div class="newsletter-box">
                        <p>Get the latest news and updates from Team Cagliero.</p>
                        <form class="newsletter-form" #newsletterForm="ngForm" (ngSubmit)='newsletterSubscribe_fun(newsletterForm.value)'>
                            <label>Enter your E-mail address:</label>
                            <div class="form-group">
                                <input required ngModel name="email" type="email" #email="ngModel" [class.invalid_input]="email.touched && !email.valid" [email]="true" class="input-newsletter" id="email" placeholder="Email address">
                                <div class="err_msg" style="color: red;" *ngIf="email.touched && !email.valid">
                                    <div *ngIf="email.errors.required">Email address is required.</div>
                                    <div *ngIf="!email.errors.required">Invalid email address</div>
                                </div>
                            </div>


                            <button type="submit"><span *ngIf="!subscribePending">Subscribe</span> <span
                                    *ngIf="subscribePending">Subscribing <i
                                        class="bx bx-loader-alt fa-spin ml0"></i></span></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="row single-footer-widget">
            <div class="col-lg-8 col-md-6 col-sm-6" align="left">
                <hr>
                <h3>Accreditation by </h3>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <a routerLink="/" class="d-inline-block"><img src="assets/img/logo/accreditation_by/dbima.png" alt="image" style="width: auto;height: 100px;margin-right: 20px;"></a>
                        <a routerLink="/" class="d-inline-block"><img src="assets/img/logo/accreditation_by/donbosco.png" alt="image" style="width: auto;height: 50px;"></a>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <a routerLink="/" class="d-inline-block"><img src="assets/img/logo/digital_partner/eu.png" alt="image" style="width: auto;height: 80px;"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6" align="right">
                <hr>
                <h3>Digital Partner </h3>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <a routerLink="/" class="d-inline-block"><img src="assets/img/logo/digital_partner/ccs.png" alt="image" style="width: auto;height: 80px;"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <div class="logo">
                <a routerLink="/" class="d-inline-block"><img src="assets/img/logo/site/logo_main.png" alt="image" style="width: auto;height: 100px;"></a>
            </div>
            <p><i class='bx bx-copyright'></i>2021 <a routerLink="/" target="_blank">Cagliero E-Cademy </a> Designed and Developed by <a href="https://www.cagliero.eu" target="_blank">Cagliero Creative Solutions</a> | All rights reserved.</p>
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>