import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { UserService } from "../../../../services/user.service";
import { Auth } from "../../../../classes/auth";
@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    sessionEmail = null;

    loginSubmitPending = false;

    constructor(
        private router: Router,
        private loginService: UserService,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit() {
        var userStatus = parseInt(localStorage.getItem("XJFYUserStatus"));
        var obj = JSON.parse(localStorage.getItem("XJFYUser"));

        if (userStatus == 1) {
            this.sessionEmail = obj.email;
        }

        this.loginForm = new FormGroup({
            username: new FormControl(this.sessionEmail, Validators.required),
            password: new FormControl(undefined, Validators.required),
        });
    }

    onSubmit() {
        var loginFormData = this.loginForm.value;
        var validateEmail = /^[a-z]+[0-9a-zA-Z_\.]*@[a-z_]+\.[a-z\.]+$/;

        if (loginFormData.username == null) {
            this.openSnackBar("Email address is missing !", 1);
        } else if (true) {
            if (loginFormData.password == null) {
                this.openSnackBar("Password is missing !", 1);
            } else {
                this.loginSubmitPending = true;

                this.openSnackBar("Checking credentials, Please wait ...", 0);

                const auth = new Auth();
                auth.username = loginFormData.username;
                auth.password = loginFormData.password;

                this.loginService.loginUser(auth).subscribe(
                    (response) => {
                        this.openSnackBar(response.status, 1);
                        // @ts-ignore
                        if (response.status === 1) {
                            localStorage.setItem("XJFYUserStatus", "1");
                            localStorage.setItem(
                                "XJFYUser",
                                JSON.stringify(response.user)
                            );
                            localStorage.setItem(
                                "XJFYToken",
                                "Bearer " + response.token
                            );

                            this.loginSubmitPending = false;

                            this.openSnackBar(
                                "Welcome " +
                                    response.user.firstName +
                                    " " +
                                    response.user.lastName,
                                1
                            );
                            this.router.navigate([""]);
                        } else {
                            this.openSnackBar(
                                "Invalid credentials ! , Please try again",
                                1
                            );
                            this.loginSubmitPending = false;
                        }
                    },
                    (error) => {
                        console.log(error);
                        this.openSnackBar(
                            "Sorry !, Your request can't be completed right now.",
                            1
                        );
                        this.loginSubmitPending = false;
                    }
                );
            }
        } else {
            this.openSnackBar("Invalid Email address !", 1);
        }
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, "Dismiss", { duration: 3000 });
        } else if (state == 0) {
            this.snackBar.open(message, "Dismiss");
        }
    }

    sendEmail() {}
}
