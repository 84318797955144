import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
    courseID = 6;
    language;

    currentUserName: string;
    currentUserID: number;
    currentUserToken: string;

    constructor(
        private snackBar: MatSnackBar
    ) {}

    ngOnInit(): void {
        var userStatus = parseInt(localStorage.getItem("XJFYUserStatus"));
        var userObj = JSON.parse(localStorage.getItem("XJFYUser"));
        var token = localStorage.getItem("XJFYToken");

        if (userStatus == 1) {
            this.currentUserName = userObj.firstName + " " + userObj.lastName;
            this.currentUserID = userObj.id;
            this.currentUserToken = token;
        }
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;
    toggleClass2() {
        var obj = document.getElementById("lang_flag_dropdown_switcher");
        obj.classList.toggle('active');
    }

    classApplied3 = false;
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, "Dismiss", { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, "Dismiss");
        }
    }
}
