import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { Router } from "@angular/router";
import { saveFeedback } from "src/app/models/endpoints";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
    selector: "app-feedback-form",
    templateUrl: "./feedback-form.component.html",
    styleUrls: ["./feedback-form.component.scss"],
})
export class FeedbackFormComponent implements OnInit {

    currentUserName: string;
    currentUserEmail: string;
    currentUserId: number;
    currentUserToken: string;
    currentUserProgressCourseID: number;
    currentUserProgressModuleID: number;
    currentUserProgressPartID: number;
    currentUserProfileUrl: string;
    currentUserType: string;

    feedbackSubmitPending = false;
    constructor(
        private router: Router,
        private http: HttpClient,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit(): void {
        this.getCurrentUserData();
    }

    getCurrentUserData() {
        var userStatus = parseInt(localStorage.getItem("XJFYUserStatus"));
        var token = localStorage.getItem("XJFYToken");
        var userObj = JSON.parse(localStorage.getItem("XJFYUser"));

        if (userStatus == 1) {
            this.currentUserName = userObj.firstName + " " + userObj.lastName;
            this.currentUserId = userObj.id;
            this.currentUserToken = token;
            this.currentUserEmail = userObj.email;
            this.currentUserProfileUrl = userObj.profileUrl;
            if (userObj.type == 4) {
                this.currentUserType = "Student";
            } else if (userObj.type == 5) {
                this.currentUserType = "Teacher";
            } else {
                this.currentUserType = userObj.description;
            }
        } else {
            this.router.navigate(["error/401"]);
        }
    }

    feedback_submit(form) {
        this.feedbackSubmitPending = true;
        this.http
            .post<any>(
                environment.baseUrl + saveFeedback,
                {
                    status: false,
                    userId: this.currentUserId,
                    feedback: form.message,
                },
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.openSnackBar("Feedback Submitted", 1);
                        this.redirectTo("give-us-your-feedback");
                    } else {
                        this.openSnackBar(data.message, 1);
                    }
                    this.feedbackSubmitPending = false;
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                    this.feedbackSubmitPending = false;
                },
            });
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, "Dismiss", { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, "Dismiss");
        }
    }

    redirectTo(uri: string) {
        this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() => this.router.navigate([uri]));
    }
}
