import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iframe-loader',
  templateUrl: './iframe-loader.component.html',
  styleUrls: ['./iframe-loader.component.scss']
})
export class IframeLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
