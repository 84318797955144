<app-header></app-header>
<br><br><br><br>


<div class="courses-details-area pt-100 pb-70">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <div class="courses-title">
                        <div class="row">
                            <div class="col-10">
                                <h2 routerLink="/learn-film-marking-with-dbima/module/{{tutorialData.id}}" class="cursor_p"><i class='bx bx-box'></i> {{tutorialData.tutorialName}}</h2>
                                <h4 *ngFor="let part of partData">
                                    <div *ngIf="lessonID == part.id" style="margin-left: 35px;">
                                        <i class='bx bx-book'></i> {{part.partName}}
                                    </div>
                                </h4>
                            </div>
                            <div class="col-2" style="text-align: right;">
                                <a href="learn-film-marking-with-dbima/dashboard"><i class='bx bx-grid-alt cs-xlarge cursor_p cs-text-gray cs_nav_icon' style="margin-top: 20px;"></i></a>
                            </div>
                        </div>

                        <br>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">

            <div class="col-lg-4">
                <app-module-navigation></app-module-navigation>
            </div>

            <div class="col-lg-1"></div>

            <div class="col-lg-6">
                <div class="courses-details-image cs-card">
                    <div id="watch_video">
                        <iframe width="100%" height="300px" src="/layout/iframe-loader" id="youTubeTutorialIframe" align='center' allowFullscreen='true'>
                        </iframe>
                        <div style="padding: 30px;">
                            <!--<p>{{youTubeURL}}</p>-->

                            <div class="hero-banner-content black-color">
                                <div class="btn-box zero_">
                                    <a class="optional-btn optional-btn-css font_w_l" (click)="toggle_transcript()" id="transcript_toggle_btn">VIEW TRANSCRIPT</a>
                                </div>
                            </div>

                            <a class="default-btn mb20 font_w_l cursor_p" href="learn-film-marking-with-dbima/module/{{moduleID}}/lesson/{{lessonID}}/quiz">
                                <i class='bx bx-analyse icon-arrow before'></i>
                                <span class="label">Check your knowledge</span>
                                <i class="bx bx-analyse icon-arrow after"></i>
                            </a>
                            <!--{{lessonPartData.partTranscript}}-->
                            <div id="transcript" class="cs-hide">

                                <div class="row">
                                    <div class="col-12">
                                        <div class="cs-right">
                                            <button class="cs-button cs-hide" id="stop_btn" (click)="stop_textToSpeak()"><i class='bx bx-stop-circle'></i></button>
                                            <button class="cs-button cs-hide" id="pause_btn" (click)="pause_textToSpeak()"><i
                                                    class='bx bx-pause-circle'></i></button>
                                            <button class="cs-button cs-hide" id="resume_btn" (click)="resume_textToSpeak()"><i
                                                    class='bx bx-play-circle'></i></button>
                                            <button class="cs-button " id="speak_btn" (click)="speak_textToSpeak('transcript_text')"><i
                                                    class='bx bx-volume-full'></i></button>
                                            <button class="cs-button" (click)="toggle_settings_textToSpeak()"><i
                                                    class='bx bx-cog'></i></button>
                                        </div>
                                    </div>
                                </div>

                                <div class="row cs-hide" id="settings_textToSpeak_div" style="margin-top: 20px;">
                                    <select id='voiceList' class=" form-control cursor_p"></select>
                                    <hr>
                                </div>


                                <p id="transcript_text">{{lessonPartData.partTranscript}} <br><br>A paragraph is a series of related sentences developing a central idea, called the topic. Try to think about paragraphs in terms of thematic unity: a paragraph is a sentence or
                                    a group of sentences that supports one central, unified idea.</p>

                                <br>
                                <div class="hero-banner-content black-color mb20">
                                    <div class="btn-box zero_">
                                        <a class="optional-btn optional-btn-css" (click)="toggle_transcript()" id="btnsdgsad">HIDE TRANSCRIPT</a>
                                    </div>
                                </div>
                                <hr>
                            </div>
                            <div class="text_a_j mt20">
                                <h3 style="font-family: Verdana, Geneva, Tahoma, sans-serif;">Key learning</h3>
                                <p>Dive into the Digital Garage and explore how you can make the most of online opportunities. This introductory video will give you a brief overview of what you can learn throughout the course and highlight some of the benefits
                                    of building or polishing your digital skills.</p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <div class="col-lg-1"></div>

        </div>
    </div>
</div>
