import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homethree-about',
  templateUrl: './homethree-about.component.html',
  styleUrls: ['./homethree-about.component.scss']
})
export class HomethreeAboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
