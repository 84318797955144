import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { Router } from "@angular/router";
import { getAllFeedbacks } from "src/app/models/endpoints";
import { MatSnackBar } from "@angular/material/snack-bar";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
    selector: "app-feedback-display",
    templateUrl: "./feedback-display.component.html",
    styleUrls: ["./feedback-display.component.scss"],
})
export class FeedbackDisplayComponent implements OnInit {
    testimonialsSlides: OwlOptions = {
        loop: true,
        nav: false,
        dots: true,
        autoplayHoverPause: true,
        autoplay: true,
        center: true,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>",
        ],
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 2,
            },
            768: {
                items: 2,
            },
            1200: {
                items: 3,
            },
        },
    };

    feedbackList;
    feedbackListCount = 0;

    constructor(
        private router: Router,
        private http: HttpClient,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit(): void {
        this.loadData();
    }

    loadData() {
        this.http.get<any>(environment.baseUrl + getAllFeedbacks).subscribe({
            next: (data) => {
                if (data.status == 1) {
                    this.feedbackList = data.feedbackDtoList;
                    this.feedbackListCount = this.feedbackList.length;
                }
            },
            error: (error) => {
                this.openSnackBar("There was an error! ," + error.message, 0);
            },
        });
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, "Dismiss", { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, "Dismiss");
        }
    }
}
