import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { registerUrl } from "../../../../models/endpoints";
import { emailCheckUrl } from "../../../../models/endpoints";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
@Component({
    selector: "app-register",
    templateUrl: "./register.component.html",
    styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {

    constructor(
        private router: Router,
        private http: HttpClient,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit(): void {}

    submit(form) {
        this.openSnackBar("Checking Details, please wait ...", 0);

        var email = form.email;
        var password = form.password;
        var confirm_password = form.confirm_password;

        this.http
            .get<any>(environment.baseUrl + emailCheckUrl +email)
            .subscribe({
                next: (data) => {

                    if(data.status == 1){
                        this.openSnackBar("This Email address already have a account", 1);
                    }else if(data.status == 0){
                        if(password == confirm_password){
                            this.registerUser(form);
                        }else{
                            this.openSnackBar("Password mismatched", 1);
                        }
                    }else{
                        this.openSnackBar("Error ocurred!, please try again later", 1);
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    registerUser(form){
        var fname = form.fname;
        var lname = form.lname;
        var role = form.role;
        var role_name = form.role_name;
        var email = form.email;
        var password = form.password;
        var place = form.place;

        this.http
            .post<any>(environment.baseUrl + registerUrl, {
                firstName: fname,
                lastName: lname,
                type: role,
                username: email,
                password: password,
                email: email,
                phoneNumber: '',
                profileUrl: "null",
                active: true,
                description: role_name,
                organization: place
            })
            .subscribe({
                next: (data) => {
                    if(data.status == 1){
                        this.openSnackBar("Account Created, login with your credentials", 1);
                        localStorage.clear();
                        this.router.navigate(["login"]);
                    }else{
                        this.openSnackBar(data.message, 1);
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, "Dismiss", { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, "Dismiss");
        }
    }
}
