import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { verifyCertification } from "../../../models/endpoints";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";

@Component({
    selector: "app-certification-verify",
    templateUrl: "./certification-verify.component.html",
    styleUrls: ["./certification-verify.component.scss"],
})
export class CertificationVerifyComponent implements OnInit {
    verified = false;
    verified_userName;
    verified_userLocation;
    verified_userProfilePicURL;
    verified_userDOB;
    verified_userCourseName;
    verified_userIssuedData;

    submitPending = false;

    invalid_id = false;

    constructor(
        private router: Router,
        private http: HttpClient,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit(): void {}

    onsubmit(form) {
        this.submitPending = true;
        this.http
            .get<any>(
                environment.baseUrl +
                    verifyCertification +
                    form.certification_id
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.verified = true;
                        this.verified_userName =
                            data.firstName + " " + data.lastName;
                        this.verified_userLocation = data.country;
                        this.verified_userDOB = data.dob;
                        this.verified_userCourseName = data.courseName;
                        this.verified_userIssuedData = data.issuedData;
                        if (
                            data.profileUrl == "" ||
                            data.profileUrl == null ||
                            data.profileUrl == "null"
                        ) {
                            this.verified_userProfilePicURL =
                                "assets/img/logo/site/logo.png";
                        } else {
                            this.verified_userProfilePicURL = data.profileUrl;
                        }
                        this.submitPending = false;
                    } else {
                        this.verified = false;
                        this.invalid_id = true;
                        this.submitPending = false;
                        //this.openSnackBar(data.message, 1);
                    }
                },
                error: (error) => {
                    if (error.status == 500) {
                        this.verified = false;
                        this.invalid_id = true;
                    } else {
                        this.openSnackBar(
                            "There was an error! ," + error.message,
                            0
                        );
                    }
                },
            });
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, "Dismiss", { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, "Dismiss");
        }
    }
}
